import { useAsyncFn } from "react-use";
import { moderationRequest } from "src/network";

export const useTextAIDecision = () => {
    const [aiDecisionState, fetchAIDecisionForText] = useAsyncFn(
      async (text: string) => {
        try {
          const result = await moderationRequest.getAIDecisionForText(text);
          return result;
        } catch (error) {
          return null;
        }
      },
      [],
      { loading: true },
    );
  
    return { aiDecisionState, fetchAIDecisionForText };
  };
  