import { Gender, Media } from '../user';
import { ApiUserPreferences, GenderIdentityPreferences } from '../user/preferences';

export enum WaitingListActionType {
  Approve = 'approve',
  Decline = 'decline',
}

export type UserForWaitingList = {
  userId: string;
  gender?: Gender;
  age?: number;
  name?: string;
  desiredRelationship?: string;
  genderIdentity?: GenderIdentityPreferences;
  mainMedia?: Media;
  interests?: string[];
  country?: string;
  city?: string;
  declinedCount?: number;
  media?: Media[];
  preferences?: ApiUserPreferences;
  height?: number;
};
