import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  itemContainer: {
    display: 'flex',
    borderRadius: '24px',
    border: '1px solid #EDEDED',
    backgroundColor: '#FFFFFF',
    marginBottom: '8px',
  },

  itemSelectedContainer: {
    backgroundColor: '#DA0767',
  },

  itemText: {
    textAlign: 'start',
    padding: '9px 16px',
    fontSize: '12px',
  },

  itemSelectedText: {
    color: '#FFFFFF',
  },
});
