import { Logger, LoggerServices, LoggerMessages } from 'src/infrastructure/loggers/datadog';
import { Product } from 'src/types/product';

export const logError = (action: string, payload: Record<string, unknown>) => {
  Logger.log({
    service: LoggerServices.RiskModeration,
    message: LoggerMessages.RiskModerationError,
    product: Product.Once,
    payload: {
      message: `Error - ${action}`,
      ...payload,
    },
  });
};
