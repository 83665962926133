import React from 'react';

import { FormAction, FormType } from '../../types';

import { CreateOperatorForm } from './forms/create-operator';
import { ChangeOperatorCommissionForm } from './forms/change-operator-commission';
import { CreateAgencyForm } from './forms/create-agency';
import { ChangeAgencyCommissionForm } from './forms/change-agency-commission';
import { ChangeOperatorActiveStatusForm } from './forms/change-operator-active-status';
import { ChangeAgencyActiveStatusForm } from './forms/change-agency-active-status';

type Props = {
  type: FormType;
  action: FormAction;
};

export const ManagingForm = (props: Props) => {
  const { type, action } = props;

  switch (type + action) {
    case FormType.Operator + FormAction.Create:
      return <CreateOperatorForm />;

    case FormType.Agency + FormAction.Create:
      return <CreateAgencyForm />;

    case FormType.Operator + FormAction.ChangeCommission:
      return <ChangeOperatorCommissionForm />;

    case FormType.Agency + FormAction.ChangeCommission:
      return <ChangeAgencyCommissionForm />;

    case FormType.Operator + FormAction.Deactivate:
    case FormType.Operator + FormAction.Reactivate:
      return <ChangeOperatorActiveStatusForm type={action as FormAction.Deactivate | FormAction.Reactivate} />;

    case FormType.Agency + FormAction.Deactivate:
    case FormType.Agency + FormAction.Reactivate:
      return <ChangeAgencyActiveStatusForm type={action as FormAction.Deactivate | FormAction.Reactivate} />;

    default:
      return null;
  }
};
