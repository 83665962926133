import { object, string, mixed, SchemaOf } from 'yup';

import { OperatorCommission } from 'src/types/operator';
import { defaultCommission, validCommissions } from 'src/services/operator/config';
import { ChangeOperatorCommissionPayload } from 'src/services/operator/hooks';

export type ChangeOperatorCommissionForm = ChangeOperatorCommissionPayload;

export const defaultValues: ChangeOperatorCommissionForm = {
  operatorId: '',
  commission: defaultCommission,
};

export const validationSchema: SchemaOf<ChangeOperatorCommissionForm> = object().shape({
  operatorId: string().default(defaultValues.operatorId).required('Operator id is required'),
  commission: mixed<OperatorCommission>()
    .default(defaultValues.commission)
    .oneOf(validCommissions)
    .required('Commission is required'),
});
