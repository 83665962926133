import { useCallback, useMemo } from 'react';

import { useSnackbar } from 'notistack';

import { Media, MediaTag } from 'src/types/user';
import { PhotoTransformation, useGetMediaSourceAuthed } from 'src/components/Moderation/utils/image-source';

import { mediaItemSize } from '../components/media-item/styles';
import { mergeAndDownloadImages } from '../utils';

type Props = {
  userId: string;
  userMedia: Media[];
  newMediaBaseName?: string;
  showHiddenMedia: boolean;
};

export const usePrepareMedia = ({ userId, userMedia, newMediaBaseName, showHiddenMedia }: Props) => {
  const getMediaSource = useGetMediaSourceAuthed();
  const { enqueueSnackbar } = useSnackbar();

  const sortedUserMedia = useMemo(() => {
    const mainMediaIndex = userMedia.findIndex((it) => it.tags.includes(MediaTag.Thumbnail));
    const newMediaIndex = userMedia.findIndex((it) => it.baseName === newMediaBaseName);
    const isMainAndNewTheSameMedia = mainMediaIndex !== -1 && newMediaIndex !== -1 && mainMediaIndex === newMediaIndex;
  
    const sortedMedia = userMedia.filter(
      (it, index) =>
        index !== mainMediaIndex && index !== newMediaIndex && (showHiddenMedia || !it.tags.includes(MediaTag.Hidden)),
    );
  
    if (isMainAndNewTheSameMedia) {
      sortedMedia.unshift(userMedia[newMediaIndex]);
    } else {
      if (newMediaIndex !== -1) {
        sortedMedia.unshift(userMedia[newMediaIndex]);
      }
  
      if (mainMediaIndex !== -1) {
        sortedMedia.unshift(userMedia[mainMediaIndex]);
      }
    }
  
    sortedMedia.unshift((undefined as unknown) as Media);
  
    return sortedMedia;
  }, [newMediaBaseName, showHiddenMedia, userMedia]);

  const getMediaSourcesArray = useCallback(() => {
    return sortedUserMedia
      .filter(Boolean)
      .map((media) =>
        getMediaSource(
          userId,
          media.baseName,
          media.mediaType,
          true,
          mediaItemSize,
          PhotoTransformation.KeepAspectRatio,
        ),
      );
  }, [getMediaSource, sortedUserMedia, userId]);

  const downloadUserMedia = useCallback(() => {
    const imageSources = getMediaSourcesArray();

    mergeAndDownloadImages(
      imageSources,
      userId,
      () => {
        enqueueSnackbar('Images successfully merged', { variant: 'success' });
      },
      () => {
        enqueueSnackbar('Error while merging images. Please try again or reload', { variant: 'error' });
      },
    );
  }, [enqueueSnackbar, getMediaSourcesArray, userId]);

  return {
    sortedUserMedia,
    downloadUserMedia,
  };
};
