import React from 'react';
import { Button } from '@material-ui/core';

import { Gender } from 'src/types/user';

import { useGenderStyles } from '../styles';
import { useToggleGender } from '../hooks/useToggleGender';

const genderMap = {
  [Gender.Male]: 'Man',
  [Gender.Female]: 'Woman',
};

export const GenderSwitchButton = () => {
  const { gender, isLoading, toggleGender } = useToggleGender();
  const classes = useGenderStyles({
    isMan: gender === Gender.Male,
  });

  if (!gender) {
    return null;
  }

  return (
    <Button variant="contained" className={classes.genderButton} onClick={toggleGender} disabled={isLoading}>
      {genderMap[gender]}
    </Button>
  );
};
