import React, { useCallback, useEffect, useState } from 'react';
import { debounce, isEqual } from 'lodash';

import { ActionButton, ActionButtonType, CheckBoxesList, LabelText, Option } from 'src/components/common/flure';

import { useStyles } from './styles';
import { SearchInput } from '../search-input';
import { SelectedFilters } from '../selected-filters';

type Props = {
  initialOptions: Option[];
  appliedFilters: string[];
  setAppliedFilters: React.Dispatch<React.SetStateAction<string[]>>;
  closeFilters: () => void;
};

export const Filters = React.memo(({ initialOptions, appliedFilters, setAppliedFilters, closeFilters }: Props) => {
  const classes = useStyles();
  const [options, setOptions] = useState<Option[]>(initialOptions);
  const [selected, setSelected] = useState<string[]>(appliedFilters);

  const onInputChange = useCallback(
    (event) => {
      debounce(() => {
        setOptions(initialOptions.filter((it) => it.text.toLowerCase().includes(event.target.value.toLowerCase())));
      }, 400)();
    },
    [initialOptions],
  );

  const onResetClick = useCallback(() => {
    setSelected([]);
    setAppliedFilters([]);
    closeFilters();
  }, [closeFilters, setAppliedFilters]);

  const onApplyPress = useCallback(() => {
    setAppliedFilters(selected);
    closeFilters();
  }, [closeFilters, selected, setAppliedFilters]);

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  return (
    <div className={classes.container}>
      <div className={classes.topRow}>
        <LabelText className={classes.filterTitle} text="Filter by" />
        <LabelText text="Stage Name" />
      </div>
      <div className={classes.inputContainer}>
        <SearchInput onChange={onInputChange} />
      </div>
      <SelectedFilters selected={selected} setSelected={setSelected} />
      <div className={classes.optionsContainer}>
        <CheckBoxesList options={options} selected={selected} setSelected={setSelected} isMultiple />
      </div>
      <div className={classes.buttonsContainer}>
        <ActionButton
          type={ActionButtonType.Default}
          title="Apply"
          onClick={onApplyPress}
          disabled={isEqual(appliedFilters, selected)}
        />
        <ActionButton type={ActionButtonType.Stroke} title="Reset" onClick={onResetClick} />
      </div>
    </div>
  );
});
