import React from 'react';
import { Box, Button, FormControl, InputAdornment, OutlinedInput, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { Layout } from 'src/components/App/views/Layout';

import { RiskModerationRoutes } from '../../constants';
import { useSearchByUserId } from '../../hooks/useSearchByUserId';
import { useStyles } from './styles';

export const RiskModerationSearchScreen = () => {
  const classes = useStyles();
  const { searchText, onTextChange, onSubmit } = useSearchByUserId();

  return (
    <Layout containerSize="lg">
      <Box className={classes.container} component="form" onSubmit={onSubmit}>
        <Typography className={classes.title}>Use Search by ID to Find the User</Typography>

        <FormControl variant="outlined" className={classes.formControl}>
          <OutlinedInput
            value={searchText}
            onChange={onTextChange}
            endAdornment={
              searchText ? (
                <InputAdornment position="end">
                  <Button component={Link} to={RiskModerationRoutes.User(searchText)} className={classes.findButton}>
                    Find
                  </Button>
                </InputAdornment>
              ) : null
            }
            placeholder="Search by id"
            className={classes.textInput}
            labelWidth={0}
          />
        </FormControl>
      </Box>
    </Layout>
  );
};
