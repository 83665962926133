import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, InputBase, Modal, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from '../styles';

type GemsModalProps = {
  balance: number;
  onAction: (type: 'credit' | 'debit', amount: number) => void;
  onCancel: () => void;
};

type ModalState = 'start' | 'credit' | 'debit';
type ModalStateData = {
  title: string;
  caption: string;
  inputTitle?: string;
  action: {
    disabled?: boolean;
    title: string;
    fn?: () => void;
  };
  secondaryAction?: {
    disabled?: boolean;
    title: string;
    fn: () => void;
  };
};

export const CreditGemsModal: React.FC<GemsModalProps> = ({ balance, onAction, onCancel }) => {
  const classes = useStyles();
  const [state, setState] = useState<ModalState>('start');
  const [balanceAmount, setBalanceAmount] = useState(0);

  const onInputChanged: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const parsedAmount = parseInt(event.target.value, 10) || 0;
    const newAmount = Math.max(parsedAmount, 0);
    if (state === 'debit' && newAmount > balance) {
      return;
    }

    setBalanceAmount(newAmount);
  };

  const onActionHandler = () => {
    if (balanceAmount === 0 || state === 'start') {
      return;
    }

    onAction(state, balanceAmount);
  };

  useEffect(() => {
    setBalanceAmount(0);
  }, [state]);

  const stateData = useMemo<ModalStateData>(() => {
    if (state === 'credit') {
      return {
        title: 'Credit Gems',
        caption: `Balance: ${balance || 0} Gems`,
        inputTitle: 'Amount of Gems',
        action: {
          title: 'Credit Gems',
        },
      };
    }
    if (state === 'debit') {
      return {
        title: 'Debit Gems',
        caption: `Balance: ${balance || 0} Gems`,
        inputTitle: 'Amount of Gems',
        action: {
          title: 'Debit Gems',
        },
      };
    }

    return {
      title: `Balance: ${balance || 0} Gems`,
      caption: 'What do you want?',
      action: {
        title: 'Debit Gems',
        disabled: balance === 0,
        fn: () => setState('debit'),
      },
      secondaryAction: {
        title: 'Credit Gems',
        fn: () => setState('credit'),
      },
    };
  }, [balance, state]);

  return (
    <Modal open className={classes.modal}>
      <Box className={clsx(classes.modalContent, classes.gemsModalContent)}>
        <Typography component="h4" className={classes.modalTitle}>
          {stateData.title}
        </Typography>

        <Typography className={classes.modalText}>{stateData.caption}</Typography>

        <Box className={clsx(classes.modalButtonContainer, classes.gemsModalButtonContainer)}>
          {stateData.inputTitle && (
            <InputBase
              className={classes.modalInput}
              placeholder={stateData.inputTitle}
              value={balanceAmount || ''}
              onChange={onInputChanged}
            />
          )}

          {stateData.secondaryAction && (
            <Button
              variant="contained"
              className={clsx(classes.modalButton, classes.modalGemsSecondaryAction)}
              onClick={stateData.secondaryAction.fn}
            >
              {stateData.secondaryAction.title}
            </Button>
          )}

          <Button
            variant="contained"
            className={clsx(classes.modalButton, classes.modalGemsAction)}
            disabled={stateData.action.disabled}
            onClick={stateData.action.fn || onActionHandler}
          >
            {stateData.action.title}
          </Button>

          <Button variant="contained" className={clsx(classes.modalButton, classes.modalCancel)} onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
