import { useCallback, useState } from 'react';
import { userRequest } from 'src/network';
import { useDataContext, useActionsContext } from './useContextHooks';
import { useActionErrorHandler } from './useActionErrorHandler';
import { useModerationDetails } from './useModerationDetails';
import { AnnalsLogEvent, useAnnalsEventLog } from './useAnnalsEventLog';

export const useEmailSubscriptionAction = () => {
  const { userId, operatorRoles } = useModerationDetails();
  const [isLoading, setIsLoading] = useState(false);
  const { emailSubscription } = useDataContext();
  const { updateEmailSubscription } = useActionsContext();
  const hasEmailSubscriptionData = emailSubscription !== null;
  const errorHandler = useActionErrorHandler();
  const logEvent = useAnnalsEventLog();

  const toggleEmailSubscription = useCallback(() => {
    setIsLoading(true);
    const request = emailSubscription ? userRequest.deleteEmailSubscription : userRequest.addEmailSubscription;
    const event: AnnalsLogEvent = {
      action: emailSubscription ? 'removed-email-notifications' : 'added-email-notifications',
    };

    request(userId)
      .then(updateEmailSubscription)
      .then(() => {
        logEvent(event);
      })
      .catch((error) =>
        errorHandler(emailSubscription ? 'Email Unsubscribe' : 'Email Subscribe', {
          error,
        }),
      )
      .finally(() => setIsLoading(false));
  }, [emailSubscription, errorHandler, logEvent, updateEmailSubscription, userId]);

  return {
    available: hasEmailSubscriptionData && operatorRoles.isProfileOrAdmin,
    emailUnsubscribed: !emailSubscription,
    isLoading,
    toggleEmailSubscription,
  };
};
