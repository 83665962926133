import React, { useCallback } from 'react';
import { useSnackbar as useSnackbarExternal } from 'notistack';
import Button from '@material-ui/core/Button';
import { uniqueId } from 'lodash';

export const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbarExternal();

  const showSuccessMessage = useCallback(
    (message: string, autoHide?: boolean) => {
      const key = uniqueId();
      enqueueSnackbar(message, {
        key,
        variant: 'success',
        persist: !autoHide,
        style: { whiteSpace: 'pre-line' },
        action: () => (
          <Button style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
            Close
          </Button>
        ),
      });
    },
    [enqueueSnackbar, closeSnackbar],
  );

  const showErrorMessage = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'error' });
    },
    [enqueueSnackbar],
  );

  return { showErrorMessage, showSuccessMessage };
};
