import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  userInfoContainer: {
    display: 'flex',
    columnGap: '30px',
    marginBottom: '7px',
  },

  userThumbnail: {
    width: '161px',
    height: '161px',
    objectFit: 'cover',
  },

  textsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
  },

  nameText: {
    fontFamily: 'SFCompactDisplay',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '48px',
  },

  smallText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
  },

  link: {
    color: 'black',
  },

  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '20px',
  },

  chatsLabel: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    lineHeight: '18px',
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 700,
    padding: '1px 10px',
    backgroundColor: '#EA4551',
    borderRadius: '5px',
  }
});
