import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>({
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '30px',
    minWidth: '247px',
    paddingRight: '32px',
  },

  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '30px',
  },

  blockTitle: {
    fontSize: '18px',
    lineHeight: '30px',
    color: '#878E9C',
    opacity: 0.5,
  },

  fieldContainer: {
    paddingLeft: '32px',
    borderLeftWidth: '7px',
    borderLeftColor: 'transparent',
    borderLeftStyle: 'solid',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  nameContainer: {
    display: 'flex',
  },

  fieldChangedRed: {
    borderLeftColor: '#F31D34',
  },

  name: {
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '43px',
    color: '#020D3D',
    marginBottom: '16px',
  },

  bio: {
    fontSize: '18px',
    lineHeight: '21px',
    color: '#878E9C',
    overflowY: 'auto',
    height: '90px',
    marginBottom: '8px',
  },
});
