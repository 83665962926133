import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },

  itemContainer: {
    display: 'flex',
    borderRadius: '24px',
    border: '1px solid #EDEDED',
    backgroundColor: '#FFFFFF',
  },

  itemYellowContainer: {
    border: '1.5px solid #DA0767',
  },

  itemRedContainer: {
    backgroundColor: '#DA0767',
  },

  itemText: {
    textAlign: 'start',
    padding: '9px 16px',
    fontSize: '12px',
  },

  itemYellowText: {
    color: '#DA0767',
  },

  itemRedText: {
    color: '#FFFFFF',
  },
});
