import qs from 'qs';
import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { verificationPhotoSize } from 'src/components/Moderation/utils/image-source';
import { verificationRequest } from 'src/network/verification';
import { VerificationPhotoHistoryItem } from 'src/network/verification/types';
import { useAuth } from 'src/services/auth';
import { UserPreferences } from 'src/types/user/preferences';
import { getUserAge } from 'src/utils/transform';

export const useNextUser = () => {
  const { me } = useAuth();
  const operatorId = me?.id || '';

  const [nextUserState, loadNextUser] = useAsyncFn(
    async () => {
      // юзер на проверку
      const userId = await verificationRequest.getUserId(operatorId);

      if (userId) {
        try {
          const [media, verificationPhoto] = await Promise.all([
            verificationRequest.getUserMedia(userId),
            verificationRequest.getVerificationPhoto(userId, verificationPhotoSize),
          ]);
          // берем только того участника, у кого имеется фото на проверку
          if (verificationPhoto) {
            // вернем ID участника, фотку на проверку, прежние фото
            return {
              userId,
              verificationPhoto,
              media,
            };
          }
        } catch (e) {} // eslint-disable-line
      }

      return null;
    },
    [],
    { loading: true },
  );

  useEffect(() => {
    loadNextUser().then();
  }, [loadNextUser]);

  return {
    nextUserState,
    loadNextUser,
  };
};

export const useNextUserFlure = () => {
  const { me } = useAuth();
  const operatorId = me?.id || '';

  const [nextUserState, loadNextUser] = useAsyncFn(
    async () => {
      const { userId, aiDecision } = (await verificationRequest.getVerificationContent(operatorId)) || {};

      if (userId) {
        try {
          const user = await verificationRequest.getUser(userId);

          return {
            userId,
            gender: user?.gender,
            genderIdentity: user?.preferences?.[UserPreferences.GenderIdentity],
            media: user?.media,
            mediaCount: user?.media?.length,
            age: user?.birthday && getUserAge(user.birthday),
            country: user?.country,
            city: user?.city,
            aiDecision,
          };
        } catch (e) {} // eslint-disable-line
      }

      return null;
    },
    [],
    { loading: true },
  );

  useEffect(() => {
    loadNextUser().then();
  }, [loadNextUser]);

  return {
    nextUserState,
    loadNextUser,
  };
};

export const useVerificationContentCount = () => {
  const { me } = useAuth();
  const operatorId = me?.id || '';

  const [contentCountState, fetchContentCount] = useAsyncFn(
    async () => {
      try {
        const result = await verificationRequest.getVerificationContentCount(operatorId);
        return result;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return {
    contentCountState,
    fetchContentCount,
  };
};

export const useVerificationPhotosHistory = () => {
  const [verificationPhotosHistory, setVerificationPhotosHistory] = useState<VerificationPhotoHistoryItem[]>([]);

  const [verificationPhotosState, fetchVerificationPhotos] = useAsyncFn(
    async ({ omit, select, mediaSources, reload }) => {
      try {
        const query = qs.stringify({ mediaSources }, { arrayFormat: 'repeat' });
        const result = await verificationRequest.getVerificationPhotosHistory({ omit, select, query });

        if (reload) {
          setVerificationPhotosHistory(result);
          return result;
        }

        setVerificationPhotosHistory((state) => [...state, ...result]);
        return [...verificationPhotosHistory, ...result];
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return {
    loading: verificationPhotosState.loading,
    verificationPhotosHistory,
    fetchVerificationPhotos,
  };
};

export const useVerificationMediaSources = () => {
  const [mediaSourcesState, fetchMediaSources] = useAsyncFn(
    async () => {
      try {
        const result = await verificationRequest.getVerificationMediaSources();
        return result;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  return {
    mediaSourcesState,
    fetchMediaSources,
  };
};
