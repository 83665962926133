import { useCallback } from 'react';
import generator from 'generate-password';
import { nanoid } from 'nanoid';

import { Gender, UserDto } from 'src/types/user';
import {
  operatorRequest,
  authRequest,
  userRequest,
  UpdateUserPayload,
  UpdateUserLocationPayload,
  IdentityCredentials,
} from 'src/network';
import { getLocation, locationHasError } from 'src/network/location';
import { Product } from 'src/types/product';
import { getRealm } from 'src/utils/realm';
import { ActivateOperatorPayload } from 'src/network/operator/types';
import { useAuth } from 'src/services/auth';
import { OperatorCities, OperatorCommission } from 'src/types/operator';
import { useSnackbar } from 'src/utils/snackbar';

import { operatorLocations, DEFAULT_LANGUAGE } from '../config';

export type CreateOperatorPayload = {
  firstName: string;
  lastName: string;
  commission: OperatorCommission;
  isReadyForExplicit: boolean;
  city: OperatorCities;
  isFreeMember?: boolean;
  freeMemberAnimatorClientId?: string;
  animatorCity?: string;
  animatorCountry?: string;
  agencyTechName?: string;
};

export const useCreateOperator = () => {
  const { showSuccessMessage, showErrorMessage } = useSnackbar();

  const { me } = useAuth();

  const createOperator = useCallback(
    async (payload: CreateOperatorPayload) => {
      const isOnceProduct = me?.realm === Product.Once;

      try {
        const { country, latitude, longitude, language } = operatorLocations[payload.city];

        let location: UpdateUserLocationPayload = {
          country,
          latitude,
          longitude,
          city: payload.city,
          languages: [language],
        };

        if (payload.isFreeMember) {
          const animatorData: Partial<UserDto> & { error?: string } = await userRequest
            .getById(payload.freeMemberAnimatorClientId!, true)
            .catch(() => ({ error: 'Wrong Animator ID' }));

          if (animatorData.error) {
            throw new Error(animatorData.error);
          }

          const locationData = await getLocation({
            city: payload.animatorCity!,
            country: payload.animatorCountry!,
          });

          if (locationHasError(locationData)) {
            throw new Error(
              `Wrong location data for city: ${payload.animatorCity}, country: ${payload.animatorCountry}. Error status: ${locationData.error}`,
            );
          }

          location = {
            city: payload.animatorCity,
            country: payload.animatorCountry,
            latitude: locationData.lat,
            longitude: locationData.lng,
            languages: [DEFAULT_LANGUAGE],
          };
        }

        const registerData: IdentityCredentials = {
          email: `atolive1+${nanoid(6)}@gmail.com`,
          password: generator.generate({
            length: 10,
            numbers: true,
            symbols: true,
          }),
        };
        const { data: identity } = await authRequest.register(registerData);

        const updateUserPayload: UpdateUserPayload = {
          name: `${payload.firstName}_${payload.lastName}`,
          birthday: '1995-04-01T00:00:00.000Z', // mocked birthday. should be just more than 18 years old
          city: location.city,
          gender: Gender.Male,
          country: location.country,
          latitude: location.latitude,
          longitude: location.longitude,
          languages: isOnceProduct ? location.languages : undefined,
        };

        if (isOnceProduct) {
          updateUserPayload.languages = location.languages;
        }

        await userRequest.update(identity.id, updateUserPayload);

        const activateOperatorPayload: ActivateOperatorPayload = {
          firstName: payload.firstName,
          realm: getRealm(me?.realm),
          lastName: payload.lastName,
          commission: payload.commission,
          isReadyForExplicit: payload.isReadyForExplicit,
          city: location.city,
          country: location.country,
          isFreeMember: payload.isFreeMember,
          freeMemberAnimatorClientId: payload.freeMemberAnimatorClientId || undefined,
          agencyTechName: payload.agencyTechName,
        };

        await operatorRequest.init(identity.id, activateOperatorPayload);

        showSuccessMessage(
          `Operator successfully created \n id: ${identity.id} \n email: ${registerData.email}  \n password: ${registerData.password}`,
        );

        return true;
      } catch (error) {
        showErrorMessage((error as Error)?.message || 'Bad request');

        return false;
      }
    },
    [showErrorMessage, showSuccessMessage, me?.realm],
  );

  return createOperator;
};
