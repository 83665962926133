import React from 'react';
import clsx from 'clsx';

import { LabelText, LabelTextSize } from 'src/components/common/flure';

import { Bubble } from '../bubble';
import { useStyles } from './styles';

type Props = {
  className?: string;
  isRecognized?: boolean | null;
  reason?: string;
};

export const AIBubble = React.memo(({ className, isRecognized, reason }: Props) => {
  const classes = useStyles();

  return (
    <Bubble className={clsx(className, reason ? classes.cursorHelp : classes.cursorDefault)} title={reason}>
      <LabelText
        className={classes.whiteText}
        text={`${isRecognized ? '🟢' : '🔴'} AI`}
        size={LabelTextSize.ExtraSmall}
      />
    </Bubble>
  );
});
