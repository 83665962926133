import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';

import { Resources } from 'src/resources';

import copyIcon from './copy-icon.svg';
import downloadIcon from './download-icon.svg';
import { useStyles } from './styles';

type Props = {
  userId: string;
  userInfoString: string;
  declinedCount?: number;
  downloadUserMedia: () => void;
};

const UserInfoView = (props: Props) => {
  const { userId, userInfoString, downloadUserMedia, declinedCount } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const copyUserId = () => {
    copy(userId);
    enqueueSnackbar(Resources.strings.moderation.userIdCopiedAlert, { variant: 'success' });
  };

  const copyUserData = () => {
    copy(userInfoString);
    enqueueSnackbar(Resources.strings.moderation.userDataCopiedAlert, { variant: 'success' });
  };

  return (
    <div className={classes.container}>
      <div className={classes.userInfoContainer}>
        <div className={classes.row}>
          <Typography className={classes.userId}>ID {userId}</Typography>
          <button type="button" className={classes.smallButton} onClick={copyUserId}>
            <img className={classes.smallButtonIcon} src={copyIcon} alt="copy ID" />
          </button>
        </div>
      </div>
      {declinedCount !== undefined && <span className={classes.redText}>{declinedCount + 1} time</span>}
      <div className={classes.buttonsContainer}>
        <button type="button" className={classes.button} onClick={copyUserData}>
          <img className={classes.buttonIcon} src={copyIcon} alt="copy" />
        </button>
        <button type="button" className={classes.button} onClick={downloadUserMedia}>
          <img className={classes.buttonIcon} src={downloadIcon} alt="download" />
        </button>
      </div>
    </div>
  );
};

export const UserInfo = memo(UserInfoView);
