import { isEmpty } from 'lodash';
import {
  genderIdentityDict,
  lookingForDict,
  partnerDict,
  relationshipPreferenceDict,
  sexualOrientationDict,
} from 'src/components/FlureFeed/constants';
import { interestsDict } from 'src/components/WaitingList/components/user-interests/interests-dictionary';
import { UserForModerationFlure } from 'src/types/moderation';
import { User } from 'src/types/user';
import { UserPreferences } from 'src/types/user/preferences';
import { UserForWaitingList } from 'src/types/waiting-list';

import { formatCountryAndCity } from './format-country-and-city';

const emptyValue = '-';

export const formatUserInfoToString = (user: UserForModerationFlure | User | UserForWaitingList) => {
  const { name, age, preferences, height, interests, city, country } = user;
  const {
    [UserPreferences.Partner]: hasPartner,
    [UserPreferences.GenderIdentity]: genderIdentity,
    [UserPreferences.SexualOrientation]: sexualOrientation,
    [UserPreferences.RelationshipPreference]: relationshipPreference,
    [UserPreferences.Relationship]: desiredRelationship,
    [UserPreferences.LookingFor]: lookingFor,
  } = preferences || {};

  let lookingForString = emptyValue;

  if (!isEmpty(lookingFor)) {
    lookingForString =
      typeof lookingFor === 'string'
        ? lookingFor
        : lookingFor?.map((it) => lookingForDict[it]).join(', ') || emptyValue;
  }

  return (
    `Name: ${name}; ` +
    `Age: ${age}; ` +
    `Relationship Status: ${hasPartner !== undefined ? partnerDict[String(hasPartner)] : emptyValue}; ` +
    `Gender: ${genderIdentity ? genderIdentityDict[genderIdentity] : emptyValue}; ` +
    `Orientation: ${sexualOrientation ? sexualOrientationDict[sexualOrientation] : emptyValue}; ` +
    `Relationship Preference: ${
      relationshipPreference ? relationshipPreferenceDict[relationshipPreference] : emptyValue
    }; ` +
    `Height: ${height ? `${height} cm` : emptyValue}; ` +
    `What I am looking for: ${desiredRelationship || emptyValue}; ` +
    `Interests: ${!isEmpty(interests) ? interests?.map((it) => interestsDict[it]).join(', ') : emptyValue}; ` +
    `From: ${formatCountryAndCity(country, city)}; ` +
    `Looking For: ${lookingForString};`
  );
};
