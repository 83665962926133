export const mergeAndDownloadImages = (imageUrls: string[], userId: string, onSuccess?: () => void, onError?: () => void) => {
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

  if (!ctx) {
    onError?.()
    return;
  }

  const promises: Promise<HTMLImageElement>[] = imageUrls.map((url) => {
    return new Promise((resolve) => {
      const img: HTMLImageElement = new Image();
      img.setAttribute('crossorigin', 'anonymous');
      img.onload = () => resolve(img);
      img.src = url;
    });
  });

  Promise.all(promises).then((images) => {
    const canvasTotalWidth: number = images.reduce((totalWidth, img) => totalWidth + img.width, 0);
    const canvasMaxHeight: number = images.reduce((maxHeight, img) => Math.max(maxHeight, img.height), 0);

    canvas.width = canvasTotalWidth;
    canvas.height = canvasMaxHeight;

    let offsetX = 0;
    images.forEach((img) => {
      ctx.drawImage(img, offsetX, 0);
      offsetX += img.width;
    });

    canvas.toBlob((blob) => {
      if (blob) {
        const link: HTMLAnchorElement = document.createElement('a');
        link.download = `${userId}_photos.png`;
        link.href = URL.createObjectURL(blob);
        link.click();

        onSuccess?.();
      } else {
        onError?.();
      }
    }, 'image/png');
  });
};
