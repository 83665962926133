import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 16,
    gap: 8,
    '@media(max-width: 760px)': {
      display: 'none',
    },
  },
  text: {
    color: '#00000080',
    fontSize: 14,
    lineHeight: '21px',
  },
});
