import React from 'react';
import { Button } from '@material-ui/core';

type Props = {
  label: string;
  disabled?: boolean;
  onClick?: () => void;
};

export const TagChipWrapper = (props: Props & { className: string }) => {
  const { label, disabled = false, onClick, className } = props;

  return (
    <Button variant="contained" className={className} onClick={onClick} disabled={disabled}>
      {label}
    </Button>
  );
};
