import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '6px',
    flexWrap: 'wrap',
    rowGap: '8px',
  },

  interestBubble: {
    display: 'flex',
    border: '1px solid #878E9C',
    padding: '5px 14px',
    borderRadius: '100px',
    backgroundColor: 'white',
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#878E9C',
  },
});
