import React from 'react';
import { Control, FieldErrors, FieldName, FieldValues } from 'react-hook-form';
import MenuItem from '@material-ui/core/MenuItem';

import ReactHookFormSelect from 'src/components/common/ReactHookFormSelect';

import { InputWrapper } from './styled';

type MenuItemValue = string | number | boolean;

export type MenuItemProps =
  | MenuItemValue
  | {
      value: MenuItemValue;
      title: string;
    };

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: FieldName<T>;
  label: string;
  errors: FieldErrors<T>;
  disabled?: boolean;
  items: MenuItemProps[];
};

const renderMenuItems = (values: MenuItemProps[]) =>
  values.map((item) => {
    const { value, title } = typeof item === 'object' ? item : { value: item, title: item.toString() };
    return (
      <MenuItem
        key={value.toString()}
        // @ts-expect-error should accept boolean value normally
        value={value}
      >
        {title}
      </MenuItem>
    );
  });

export const Select = <T extends FieldValues>(props: Props<T>) => {
  const { control, name, label, errors, disabled, items } = props;

  return (
    <InputWrapper>
      <ReactHookFormSelect
        disabled={disabled}
        size="small"
        error={!!errors[name]}
        name={name}
        label={label}
        control={control}
        variant="outlined"
      >
        {renderMenuItems(items)}
      </ReactHookFormSelect>
    </InputWrapper>
  );
};
