import React from 'react';
import { Control, Controller, FieldError, FieldErrors, FieldName, FieldValues } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';

import { InputWrapper } from './styled';

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: FieldName<T>;
  label: string;
  errors: FieldErrors<T>;
};

const fullWidthStyle = { width: '100%' };

export const TextInput = <T extends FieldValues>(props: Props<T>) => {
  const { control, name, label, errors } = props;

  return (
    <InputWrapper>
      <Controller<typeof TextField, T>
        as={TextField}
        name={name}
        type="string"
        control={control}
        label={label}
        error={!!errors[name]}
        helperText={(errors[name] as FieldError)?.message}
        variant="outlined"
        size="small"
        style={fullWidthStyle}
      />
    </InputWrapper>
  );
};
