import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 10,
    paddingBottom: '21px',
  },
});
