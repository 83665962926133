import React, { useCallback, useMemo } from 'react';
import { AppBar, Button, Toolbar } from '@material-ui/core';
import { useAuth } from 'src/services/auth';
import { Logo } from 'src/components/common/Logo';
import { getProductsMap } from 'src/components/config';
import { OperatorRolesPanel } from 'src/components/RiskModeration/views/operator-roles-panel';

import { FlureMenu, RawMenu, UDatesMenu } from './components';
import * as S from './styled';

export const Menu = () => {
  const { me, logout } = useAuth();
  const productsMap = useMemo(() => getProductsMap(me?.realm), [me?.realm]);
  const { udatesProduct, flureProduct, rawProduct } = productsMap;

  const onLogoutClick = useCallback(() => logout(), [logout]);

  return (
    <AppBar position="static">
      <Toolbar>
        <Logo />

        {flureProduct && <FlureMenu />}
        {udatesProduct && <UDatesMenu />}
        {rawProduct && <RawMenu />}

        <S.LogoutBox>
          {udatesProduct && <OperatorRolesPanel />}
          <Button color="inherit" onClick={onLogoutClick}>
            Logout
          </Button>
        </S.LogoutBox>
      </Toolbar>
    </AppBar>
  );
};
