import React from 'react';
import clsx from 'clsx';

import { Typography } from '@material-ui/core';

import { ONCE } from 'src/types/once';

import { useStyles } from './styles';

type Props = {
  contentDecision: ONCE.ContentDecision[];
  scrollRef: React.RefObject<HTMLDivElement>;
  containerClassname?: string;
};

export const OnceModerationContentLabel = (props: Props) => {
  const { scrollRef, containerClassname, contentDecision } = props;

  const classes = useStyles();

  const scrollToBlock = () => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth', // Плавная прокрутка
        block: 'start', // Скролл до начала блока
      });
    }
  };

  if (!contentDecision.length) return null;

  return (
    <button
      type="button"
      className={clsx(classes.moderationContentContainer, containerClassname)}
      onClick={scrollToBlock}
    >
      <Typography className={classes.moderationContentText}>🚫 {contentDecision.length}</Typography>
    </button>
  );
};
