const categories = {
  blood: 'Blood',
  'calls-for-terrorism': 'Calls for terrorism, extremism',
  children: 'Children',
  celebrity: 'Celebrities',
  contacts: 'Contacts',
  'dead-animals': 'Dead animals',
  'dead-bodies': 'Dead bodies',
  duplicate: 'Duplicate Photo',
  'explicit-nudity': 'Explicit nudity',
  'flipped-photo': 'Flipped photo',
  genitalia: 'Genitalia',
  guns: 'Guns',
  'hate-speech': 'Hate speech, discriminatory, offensive language',
  hunting: 'Hunting',
  knives: 'Knives',
  'logos-brands': 'Logos, brands or social networks',
  'minors-engaging': 'Minors engaging in adult behavior',
  'no-face': 'No face',
  'obscene-gestures': 'Obscene gestures',
  'prostitution-escort': 'Prostitution, escort, sex trafficking, paid dating',
  'promo-of-politic': 'Promotion of political views/religious belief',
  'provocative-poses': 'Provocative or suggestive poses',
  'racism-sexism': 'Racism, sexism, homophobia',
  'scammers-adult': 'Scammers, adult performers',
  gory: 'Scenes of violence',
  'school-uniforms': 'School uniforms',
  'several-people': 'Several people',
  'sex-toys': 'Sex toys',
  'self-harm': 'Self-harm',
  qr: 'QR code',
  underwear: 'Underwear',
  adult: 'Adult Content',
  'stop-words': 'Stop words',
};

export const useModerationContentCategoriesPhoto = () => {
  return {
    categories,
  };
};
