import { useCallback, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { filtersToSearchQuery } from 'src/components/FlureFeed/utils';
import { flureFeedRequest } from 'src/network/flure-feed';
import { ApiFeedUser, Filter, FiltersState } from 'src/network/flure-feed/types';
import { useAuth } from 'src/services/auth';
import { GenderIdentityPreferences, LookingForPreferences } from 'src/types/user/preferences';

export const useFeedUsers = () => {
  const { me } = useAuth();
  const operatorId = me?.id;
  const [usersState, setUsersState] = useState<ApiFeedUser[]>([]);

  const [users, fetchUsers] = useAsyncFn(
    async ({
      filters,
      myGenderIdentity,
      reload,
    }: {
      filters: FiltersState;
      myGenderIdentity: GenderIdentityPreferences;
      reload?: boolean;
    }) => {
      try {
        if (operatorId) {
          if (reload) {
            await flureFeedRequest.resetSkippedUsers(operatorId);
          }

          let searchQuery = filtersToSearchQuery(filters, myGenderIdentity);
          let result = await flureFeedRequest.fetchUsers(operatorId, searchQuery);

          if (
            result.length === 0 &&
            filters[Filter.LookingFor]?.length === 1 &&
            filters[Filter.LookingFor]?.[0] === LookingForPreferences.NonBinary
          ) {
            searchQuery = filtersToSearchQuery(filters, myGenderIdentity, true);
            result = await flureFeedRequest.fetchUsers(operatorId, searchQuery);
          }

          result.forEach((user) => flureFeedRequest.skipUser(operatorId, user.userId));

          if (reload) {
            setUsersState(result);
            return result;
          }
          setUsersState((state) => [...state, ...result]);
          return [...usersState, ...result];
        }

        return null;
      } catch (error) {
        return null;
      }
    },
    [],
    { loading: true },
  );

  const resetUsersState = useCallback(() => {
    setUsersState([]);
  }, []);

  return {
    loading: users.loading,
    users: usersState,
    fetchUsers,
    resetUsersState,
  };
};
