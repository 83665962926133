import { AxiosResponse } from 'axios';

import { Operator, OperatorCommission } from 'src/types/operator';
import { transformDbIdToFrontId } from 'src/utils/platform/convert-id';
import { OperatorsDto } from './types';

const getAll = (response: AxiosResponse<OperatorsDto>): Operator[] => {
  return response.data.operators.map((operatorDto) => {
    const operatorDbId = String(operatorDto.operatorId);

    const adaptedItem: Operator = {
      ...operatorDto,
      commission: operatorDto.commission as OperatorCommission,
      operatorId: transformDbIdToFrontId(operatorDbId),
      operatorDbId,
      firstName: operatorDto.firstName || '',
      lastName: operatorDto.lastName || '',
      isReadyForExplicit: operatorDto.isReadyForExplicit || false,
    };

    return adaptedItem;
  });
};

export const adapter = {
  getAll,
};
