import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  userInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
  },

  row: {
    display: 'flex',
    columnGap: '4px',
    alignItems: 'center',
  },

  userId: {
    fontSize: '16px',
    color: '#020D3D',
    fontWeight: 400,
    opacity: 0.5,
  },

  buttonsContainer: {
    display: 'flex',
    columnGap: '12px',
  },

  button: {
    width: '48px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    border: 'none',
    borderRadius: '8px',
    backgroundColor: 'white',
    padding: '0px',

    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },

  buttonIcon: {
    width: '24px',
    height: '24px',
  },

  smallButton: {
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    padding: '0px',

    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },

  smallButtonIcon: {
    width: '100%',
    height: '100%',
  },

  redText: {
    color: '#F05A2A',
    textAlign: 'center',
    fontSize: '25px',
    lineHeight: '17px',
    fontWeight: 700,
  },
});
