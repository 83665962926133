import { GenderIdentityPreferences, LookingForPreferences, RelationshipGoalsPreferences, RelationshipPreferencePreferences } from 'src/types/user/preferences';
import { Filter, FilterLocation, FiltersState } from 'src/network/flure-feed/types';
import { LocationOption } from './utils';

export type DictType = {
  [key: string]: string;
};

export const genderIdentityDict: DictType = {
  man: 'Man',
  woman: 'Woman',
  'non-binary': 'Non-binary',
  agender: 'Agender',
  androgynous: 'Androgynous',
  bigender: 'Bigender',
  'gender-fluid': 'Gender Fluid',
  'gender-nonconforming': 'Gender Nonconforming',
  genderqueer: 'Genderqueer',
  'gender-questioning': 'Gender Questioning',
  intersex: 'Intersex',
};

export const lookingForDict: DictType = {
  woman: 'Woman',
  man: 'Man',
  'non-binary': 'Non-binary',
  'woman-man': 'Woman + Man',
  'woman-woman': 'Woman + Woman',
  'man-man': 'Man + Man',
};

export const relationshipPreferenceDict: DictType = {
  monoamorous: 'Monoamorous',
  polyamorous: 'Polyamorous',
  ambiamorous: 'Ambiamorous',
};

export const genderIdentityOptions = Object.keys(genderIdentityDict).map((it) => ({
  text: genderIdentityDict[it],
  value: it,
}));

const uniqLookingForAndGenderIdentitiesKeys = new Set([
  ...Object.keys(lookingForDict),
  ...Object.keys(genderIdentityDict),
]);

export const lookingForWithGenderIdentitiesOptions = Array.from(uniqLookingForAndGenderIdentitiesKeys).map((it) => ({
  text: genderIdentityDict[it] || lookingForDict[it],
  value: it,
}));

export const lookingForOptions = Object.keys(lookingForDict).map((it) => ({
  text: lookingForDict[it],
  value: it,
}));

export const allGenderIdentities = genderIdentityOptions.map((it) => it.value);
export const allLookingFor = lookingForOptions.map((it) => it.value);

export const sexualOrientationDict: DictType = {
  straight: 'Straight',
  'gay-lesbian': 'Gay/Lesbian',
  'gay-lesbian-mal': 'Gay',
  'gay-lesbian-fem': 'Lesbian',
  bisexual: 'Bisexual',
  heteroflexible: 'Heteroflexible',
  homoflexible: 'Homoflexible',
  pansexual: 'Pansexual',
  fluid: 'Fluid',
  questioning: 'Questioning',
};

export enum FilterPartner {
  true = 'true',
  false = 'false',
}

export const partnerDict: DictType = { [FilterPartner.true]: 'With Partner', [FilterPartner.false]: 'Single' };

export const MIN_AGE = 18;
export const MAX_AGE = 80;
export const MAX_AGE_IN_SEARCH = 150;
export const DEFAULT_AGE = 20;
export const DEFAULT_AGE_RANGE = [18, 25];
export const AGE_DIFF = 10;

export const MIN_HEIGHT = 90;
export const MAX_HEIGHT = 240;
export const DEFAULT_GENDER_IDENTITY = GenderIdentityPreferences.Woman;
export const DEFAULT_LOOKING_FOR = LookingForPreferences.Man;
export const DEFAULT_LOCATION_OPTIONS: LocationOption[] = [
  {
    text: 'Virtual Realm',
    value: FilterLocation.Virtual,
    country: 'US',
  },
  {
    text: 'New York Realm',
    value: 'New York',
    country: 'US',
  },
  {
    text: 'Houston Realm',
    value: 'Houston',
    country: 'US',
  },
  {
    text: 'Dallas Realm',
    value: 'Dallas',
    country: 'US',
  },
];

export const DEFAULT_LOCATION_NAMES = DEFAULT_LOCATION_OPTIONS.map(it => it.value);

export const DEFAULT_LOCATION = {
  latitude: 'auto',
  longitude: 'auto',
};

export const defaultFiltersState: FiltersState = {
  [Filter.Age]: { min: DEFAULT_AGE_RANGE[0], max: DEFAULT_AGE_RANGE[1] },
  [Filter.Height]: { min: MIN_HEIGHT, max: MAX_HEIGHT },
  [Filter.RelationshipGoals]: Object.values(RelationshipGoalsPreferences),
  [Filter.RelationshipPreference]: Object.values(RelationshipPreferencePreferences),
  [Filter.LookingFor]: [DEFAULT_LOOKING_FOR],
  [Filter.Location]: FilterLocation.Virtual,
};