import { useMemo } from 'react';

import { UserTagType } from 'src/types/user';
import { UserGroup } from '../types';
import { useDataContext } from './useContextHooks';

const groupLabelsMap: Record<string, UserGroup> = {
  [UserTagType.Unapproved]: 'animators',
  [UserTagType.Scam]: 'scam',
};

export const useUserGroups = () => {
  const { userTags } = useDataContext();
  const groupTag = useMemo(
    () => userTags?.find((tag) => tag === UserTagType.Scam || tag === UserTagType.Unapproved) || '',
    [userTags],
  );

  return {
    isInScamGroup: groupTag === UserTagType.Scam,
    isInAnimatorsGroup: groupTag === UserTagType.Unapproved,
    currentGroup: groupLabelsMap[groupTag] || 'common',
  };
};
