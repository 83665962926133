import React from 'react';
import { Box } from '@material-ui/core';

import {
  getDictionaryValues,
  interestsDict,
} from 'src/components/WaitingList/components/user-interests/interests-dictionary';

import { useStyles } from './styles';

type Props = {
  interests?: string[];
};

export const UserInterests = (props: Props) => {
  const { interests = [] } = props;
  const classes = useStyles();
  const interestsLabels = getDictionaryValues(interests, interestsDict);

  return (
    <Box className={classes.container}>
      {interestsLabels.map((interest) => (
        <div key={interest} className={classes.interestBubble}>
          {interest}
        </div>
      ))}
    </Box>
  );
};
