import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  button: {
    padding: '10px 16px',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid black',
    borderRadius: '35px',
    textTransform: 'none',
  },

  filterIcon: {
    width: '25px',
    height: '25px',
    marginRight: '10px',
  },

  filtersContainer: {
    position: 'absolute',
    top: '140px',
    right: '24px',
    zIndex: 10,
  },
});
