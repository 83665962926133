import React, { useMemo } from 'react';

import { UserForModerationFlure } from 'src/types/moderation';

import { UserPreferences } from 'src/types/user/preferences';
import {
  genderIdentityDict,
  lookingForDict,
  partnerDict,
  relationshipPreferenceDict,
  sexualOrientationDict,
} from 'src/components/FlureFeed/constants';
import { formatCountryAndCity } from 'src/utils/format-country-and-city';
import { User } from 'src/types/user';

import { useStyles } from './styles';
import { UserInterests } from './components';

type Props = {
  user: UserForModerationFlure | User;
};

type InfoFieldProps = {
  title: string;
  value?: string | number;
};

const InfoField = ({ title, value }: InfoFieldProps) => {
  const classes = useStyles();

  return (
    <span>
      <span className={classes.titleText}>{title}: </span>
      <span className={classes.valueText}>{value || '-'}</span>
    </span>
  );
};

export const UserDetails = ({ user }: Props) => {
  const { preferences, country, city, height, interests } = user || {};
  const {
    [UserPreferences.GenderIdentity]: genderIdentity,
    [UserPreferences.SexualOrientation]: sexualOrientation,
    [UserPreferences.Partner]: hasPartner,
    [UserPreferences.RelationshipPreference]: relationshipPreference,
    [UserPreferences.LookingFor]: lookingForPreference,
  } = preferences || {};
  const classes = useStyles();
  const lookingFor = useMemo(() => {
    if (!lookingForPreference) {
      return '';
    }

    if (typeof lookingForPreference === 'string') {
      return lookingForPreference;
    }

    return lookingForPreference
      .map((it) => lookingForDict[it])
      .filter(Boolean)
      .join(', ');
  }, [lookingForPreference]);

  return (
    <div className={classes.container}>
      <div className={classes.fieldsContainer}>
        <InfoField title="Gender" value={genderIdentity ? genderIdentityDict[genderIdentity] : ''} />
        <InfoField
          title="Sexual Orientation"
          value={sexualOrientation ? sexualOrientationDict[sexualOrientation] : ''}
        />
        <InfoField title="Relationship Status" value={partnerDict[String(hasPartner)]} />
        <InfoField
          title="Relationship Preference"
          value={relationshipPreference ? relationshipPreferenceDict[relationshipPreference] : ''}
        />
        <InfoField title="Height" value={height ? `${height} cm` : ''} />
        <InfoField title="From" value={formatCountryAndCity(country, city)} />
        <InfoField title="Looking For" value={lookingFor} />
      </div>
      {!!interests && <UserInterests interests={interests} />}
    </div>
  );
};
