import React from 'react';
import { Toolbar, Box, Container, Grid, Typography, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

import { Layout } from 'src/components/App/views/Layout';

import { useStyles } from '../styles';
import { useModerationUser } from '../hooks/useModerationDetails';

import { GenderSwitchButton } from './gender-switch-button';
import { UserInfo } from './user-info';
import { SuspiciousMessagesPanel } from './suspicious-messages-panel';
import { UserModerationHistory } from './user-moderation-history';
import { ActionsPanel } from './actions-panel';
import { SearchPanel } from './search-panel';
import { UserMediaContent } from './user-media-content';

export const RiskModerationUserLayout = () => {
  const { loaded, deleted } = useModerationUser();
  const classes = useStyles();

  if (!loaded) {
    return (
      <Layout containerSize="lg">
        <Box className={clsx(classes.container, classes.containerCentred)}>
          <CircularProgress />
        </Box>
      </Layout>
    );
  }

  if (deleted) {
    return (
      <Layout containerSize="lg">
        <Toolbar className={classes.header}>
          <SearchPanel />
        </Toolbar>
        <Box className={clsx(classes.container, classes.containerCentred)}>
          <Typography className={classes.notFoundTitle}>User Deleted</Typography>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout containerSize="lg">
      <Box className={classes.container}>
        <Toolbar className={classes.header}>
          <SearchPanel />
        </Toolbar>
        <Container className={classes.contentWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box className={classes.contentLeft}>
                <GenderSwitchButton />

                <UserInfo />

                <SuspiciousMessagesPanel />

                <UserModerationHistory />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.contentRight}>
                <UserMediaContent />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box component="footer" className={classes.contentFooter}>
          <ActionsPanel />
        </Box>
      </Box>
    </Layout>
  );
};
