import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useCreateAgency } from 'src/services/agency/hooks';
import { agencyCommissions } from 'src/services/agency/config';

import { TextInput } from '../components/text-input';
import { MenuItemProps, Select } from '../components/select';
import { SubmitButton } from '../components/submit-button';
import { Form } from '../components/styled';

import { validationSchema, defaultValues } from './schema';
import { agencyLanguages } from './config';

const languageItems: MenuItemProps[] = agencyLanguages.map((lang) => ({ value: lang, title: lang.toUpperCase() }));

export const CreateAgencyForm = () => {
  const createAgency = useCreateAgency();

  const { control, handleSubmit, errors, formState, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleFormSubmit = handleSubmit(async (values) => {
    const success = await createAgency(values);
    if (success) reset();
  });

  return (
    <Form onSubmit={handleFormSubmit}>
      <TextInput name="name" label="Agency name" control={control} errors={errors} />
      <Select name="commission" label="Agency commission" control={control} errors={errors} items={agencyCommissions} />
      <Select name="language" label="Language" control={control} errors={errors} items={languageItems} />
      <SubmitButton title="Create Agency" submitting={formState.isSubmitting} />
    </Form>
  );
};
