import React, { useState } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import { Box, Button, Modal, Typography } from '@material-ui/core';

import { useStyles } from '../styles';

type ActionSwitchButtonProps = {
  disabled?: boolean;
  className?: string;
  hidden?: boolean;
  onLabel: React.ReactNode;
  offLabel: React.ReactNode;
  isOn: boolean;
  onToggle?: () => void;
  confirmationEnabled?: boolean;
  confirmationTitle?: string;
};

export const ActionSwitchButton: React.FC<ActionSwitchButtonProps> = ({
  onLabel,
  offLabel,
  isOn,
  disabled,
  hidden,
  className,
  onToggle,
  confirmationEnabled = false,
  confirmationTitle,
}) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const onConfirm = () => {
    if (onToggle) {
      onToggle();
    }
    setIsOpen(false);
  };
  const onReject = () => {
    setIsOpen(false);
  };
  const onAction = () => {
    if (confirmationEnabled) {
      setIsOpen(true);
      return;
    }

    if (onToggle) {
      onToggle();
    }
  };

  if (hidden) {
    return null;
  }

  return (
    <>
      <Button
        variant="contained"
        className={clsx(classes.moderationButton, { [classes.moderationLabel]: !onToggle }, className)}
        onClick={onAction}
        disabled={disabled}
      >
        {isOn ? (
          <>
            <CheckCircleIcon className={classes.checkedIcon} />
            {onLabel}
          </>
        ) : (
          offLabel
        )}
      </Button>

      <Modal open={isOpen} className={classes.modal}>
        <Box className={classes.modalContent}>
          <Typography component="h4" className={classes.modalTitle}>
            Are you sure?
          </Typography>
          <Box className={classes.modalButtonContainer}>
            <Button variant="contained" className={clsx(classes.modalButton, classes.modalAction)} onClick={onConfirm}>
              {confirmationTitle}
            </Button>
            <Button variant="contained" className={clsx(classes.modalButton, classes.modalCancel)} onClick={onReject}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
