import React, { useCallback, useMemo, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { ActionType, ModerationAction, UserForModerationFlure } from 'src/types/moderation';
import { Resources } from 'src/resources';
import { BlockUserModal } from 'src/components/Moderation/views/block-user-modal';
import { ActionButton } from 'src/components/Moderation/views/action-button';
import { UserDetails } from 'src/components/common/flure/user-details';
import { User, Gender } from 'src/types/user';
import { formatGenderIdentityGenderAndAge } from 'src/utils/format-gender-and-age';
import { GenderIdentityPreferences } from 'src/types/user/preferences';
import { AIBubbleForText } from 'src/components/common/flure';

import { useStyles } from './styles';

const { defaultAbout, defaultName } = Resources.strings.moderation;

type Props = {
  user?: UserForModerationFlure | User;
  resetName: () => void;
  resetAbout: () => void;
  sendModerationActions: (actions: ModerationAction[]) => Promise<void>;
  moderationActions: ModerationAction[];
  isPressed: (actionType: ActionType, actionValue?: string | undefined) => boolean;
  undoAction: (actionType: ActionType) => () => void;
  name: string;
  newUserName?: string;
  about: string | undefined;
  newUserAbout?: string;
  gender: Gender;
  genderIdentity: GenderIdentityPreferences;
  age: number;
  actionsDisabled: boolean;
};

export const TextContent = (props: Props) => {
  const {
    user,
    resetName,
    resetAbout,
    sendModerationActions,
    moderationActions,
    isPressed,
    undoAction,
    name,
    newUserName,
    about,
    newUserAbout,
    actionsDisabled,
    gender,
    genderIdentity,
    age,
  } = props;
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const toggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const blockProfile = useCallback(() => {
    toggleModal();
    sendModerationActions([...moderationActions, { actionType: ActionType.Delete }]);
  }, [sendModerationActions, toggleModal, moderationActions]);

  const { isPressedResetName, isPressedResetAbout } = useMemo(
    () => ({
      isPressedResetName: isPressed(ActionType.ResetName),
      isPressedResetAbout: isPressed(ActionType.ResetAbout),
    }),
    [isPressed],
  );

  const renderNameField = useMemo(() => (isPressedResetName ? defaultName : newUserName || name), [
    isPressedResetName,
    name,
    newUserName,
  ]);

  const nameActionButtonProps = useMemo(() => {
    if (isPressedResetName) {
      return {
        onClick: undoAction(ActionType.ResetName),
        title: Resources.strings.moderation.undoResetNameTitle,
      };
    }

    return {
      onClick: resetName,
      title: Resources.strings.moderation.resetNameTitle,
    };
  }, [isPressedResetName, undoAction, resetName]);

  const renderAboutField = useMemo(() => (isPressedResetAbout ? defaultAbout : newUserAbout || about), [
    about,
    isPressedResetAbout,
    newUserAbout,
  ]);

  const aboutActionButtonProps = useMemo(() => {
    if (isPressedResetAbout) {
      return {
        onClick: undoAction(ActionType.ResetAbout),
        title: Resources.strings.moderation.undoResetBioTitleUdates,
      };
    }

    return {
      onClick: resetAbout,
      title: Resources.strings.moderation.resetBioTitle,
    };
  }, [isPressedResetAbout, undoAction, resetAbout]);

  return (
    <Box className={classes.leftContainer}>
      <BlockUserModal isOpen={isModalOpen} onBlock={blockProfile} onCancel={toggleModal} />
      <Box className={classes.topContainer}>
        <Box className={classes.nameContainer}>
          <Box className={clsx(classes.fieldContainer, newUserName && classes.fieldChangedRed)}>
            <span className={classes.row}>
              <span className={classes.blockTitle}>{Resources.strings.moderation.nameTitle}</span>
              <AIBubbleForText className={classes.aiBubble} text={name} />
            </span>
            <Typography className={classes.name}>
              {renderNameField}, {formatGenderIdentityGenderAndAge(genderIdentity, gender, age, false)}
            </Typography>
            <ActionButton {...nameActionButtonProps} disabled={actionsDisabled} />
          </Box>
        </Box>
        <Box className={clsx(classes.fieldContainer, newUserAbout && classes.fieldChangedRed)}>
          <span className={classes.row}>
            <span className={classes.blockTitle}>{Resources.strings.moderation.lookingForTitle}</span>
            <AIBubbleForText className={classes.aiBubble} text={newUserAbout || about} />
          </span>
          <Typography className={classes.bio}>{renderAboutField}</Typography>
          <ActionButton {...aboutActionButtonProps} disabled={actionsDisabled} />
        </Box>
      </Box>
      {!!user && (
        <Box className={classes.fieldContainer}>
          <UserDetails user={user} />
        </Box>
      )}
      <Box className={classes.fieldContainer}>
        <ActionButton
          color="error"
          onClick={toggleModal}
          title={Resources.strings.moderation.blockProfile}
          disabled={actionsDisabled}
        />
      </Box>
    </Box>
  );
};
