import { object, string, SchemaOf } from 'yup';

export type ChangeOperatorCommissionForm = {
  operatorId: string;
};

export const defaultValues: ChangeOperatorCommissionForm = {
  operatorId: '',
};

export const validationSchema: SchemaOf<ChangeOperatorCommissionForm> = object().shape({
  operatorId: string().default(defaultValues.operatorId).required('Operator id is required'),
});
