import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { VerificationPhotoHistoryItem } from 'src/network/verification/types';

import { VerificationPhoto } from '../verification-photo';
import { useStyles } from './styles';

type Props = {
  data: VerificationPhotoHistoryItem[];
  loadMore: () => void;
};

export const VerificationPhotosList = React.memo(({ data, loadMore }: Props) => {
  const classes = useStyles();

  return (
    <div id="scrollableDiv" className={classes.contentContainer}>
      <InfiniteScroll
        dataLength={data.length}
        next={loadMore}
        className={classes.verificationPhotos}
        hasMore
        loader={null}
        scrollableTarget="scrollableDiv"
        hasChildren
      >
        {data.map((item) => (
          <VerificationPhoto key={item.baseName} item={item} />
        ))}
      </InfiniteScroll>
    </div>
  );
});
