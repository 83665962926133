import React from 'react';
import Button from '@material-ui/core/Button';

type Props = {
  submitting?: boolean;
  title: string;
};

export const SubmitButton = (props: Props) => {
  const { submitting, title } = props;

  return (
    <Button type="submit" variant="contained" color="primary" disabled={submitting}>
      {title}
    </Button>
  );
};
