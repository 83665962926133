import { useCallback, useState } from 'react';
import { userRequest } from 'src/network';
import { useActionsContext, useDataContext } from './useContextHooks';
import { useActionErrorHandler } from './useActionErrorHandler';
import { useModerationDetails } from './useModerationDetails';
import { useAnnalsEventLog } from './useAnnalsEventLog';

export const useCreditGemsAction = () => {
  const { userId, operatorRoles } = useModerationDetails();
  const [isLoading, setIsLoading] = useState(false);
  const { userBalance } = useDataContext();
  const { updateUserBalance } = useActionsContext();
  const errorHandler = useActionErrorHandler();
  const logEvent = useAnnalsEventLog();

  const gemsChangeAction = useCallback(
    (type: 'credit' | 'debit', amount: number) => {
      if (!userId) {
        return;
      }

      setIsLoading(true);

      const convertedAmount = amount * 10;
      const value = type === 'debit' ? -1 * convertedAmount : convertedAmount;

      userRequest
        .setBalance(userId, value.toString())
        .then(updateUserBalance)
        .then(() => {
          logEvent({
            action: 'credits-gems',
            gemsAmount: amount,
            creditType: type,
          });
        })
        .catch((error) =>
          errorHandler('Credit Gems', {
            error,
            creditType: type,
            gemsAmount: value,
          }),
        )
        .finally(() => setIsLoading(false));
    },
    [errorHandler, logEvent, updateUserBalance, userId],
  );

  return {
    available: operatorRoles.isCreditsOrAdmin,
    balance: userBalance,
    isLoading,
    gemsChangeAction,
  };
};
