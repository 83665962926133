import React, { useEffect } from 'react';

import { AIBubble } from 'src/components/common/flure';
import { useTextAIDecision } from 'src/services/moderation/flure/hooks';

type Props = {
  className?: string;
  text?: string;
};

export const AIBubbleForText = React.memo(({ className, text }: Props) => {
  const { aiDecisionState, fetchAIDecisionForText } = useTextAIDecision();

  useEffect(() => {
    if (text) {
      fetchAIDecisionForText(text);
    }
  }, [fetchAIDecisionForText, text]);

  if (!text || aiDecisionState.loading || !aiDecisionState.value) {
    return null;
  }

  const { flagged, categories } = aiDecisionState.value || {};

  return <AIBubble className={className} isRecognized={!flagged} reason={categories} />;
});
