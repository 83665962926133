import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  whiteText: {
    color: 'white !important',
  },

  cursorHelp: {
    cursor: 'help',
  },
  
  cursorDefault: {
    cursor: 'default',
  },
}));
