import React, { useState } from 'react';

import Typography from '@material-ui/core/Typography';

import { ONCE } from 'src/types/once';
import { getMediaSource } from 'src/components/Moderation/utils/image-source';

import { useStyles } from './styles';
import { StopWordDescriptionPhotoDialog } from './description-dialog';

type Props = {
  decision: ONCE.ContentDecision;
  isAdministrator: boolean;
  userId: string;
};

export const OnceModerationContentCardPhoto = React.memo((props: Props) => {
  const { isAdministrator, userId, decision } = props;

  const { initiatedBy, content: basename, categoryLevels } = decision;
  const classes = useStyles();

  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleCardClick = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const mediaSource = getMediaSource(userId, basename, 'image');

  return (
    <>
      <button type="button" className={classes.container} onClick={handleCardClick}>
        <img className={classes.photo} src={mediaSource} alt="moderation-content" />

        {isAdministrator && (
          <div className={classes.authorContainer}>
            <Typography className={classes.authorText}>{initiatedBy}</Typography>
          </div>
        )}
      </button>

      <StopWordDescriptionPhotoDialog
        mediaSource={mediaSource}
        handleClose={handleClose}
        isDialogOpen={isDialogOpen}
        categoryLevels={categoryLevels}
      />
    </>
  );
});
