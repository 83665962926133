import React, { useMemo } from 'react';
import { Button, IconButton, Menu as MenuBase } from '@material-ui/core';

type Props = {
  keyId: string;
  type: 'icon' | 'text';
  icon?: React.ReactNode;
  title?: string;
  children: React.ReactNode;
};

export const MenuButton = ({ keyId, type, icon, title, children }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const button = useMemo(() => {
    if (type === 'icon') {
      return (
        <IconButton
          aria-label="account of current user"
          aria-controls={keyId}
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          {icon}
        </IconButton>
      );
    }

    if (type === 'text') {
      return (
        <Button aria-controls={keyId} aria-haspopup="true" color="inherit" onClick={handleMenu}>
          {title}
        </Button>
      );
    }

    return null;
  }, [icon, keyId, title, type]);

  return (
    <div>
      {button}
      <MenuBase
        id={keyId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleClose}
      >
        {children}
      </MenuBase>
    </div>
  );
};
