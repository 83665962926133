import { makeStyles } from '@material-ui/core';

const imageHeight = '210px';
const imageWidth = '122px';

export const useStyles = makeStyles({
  verificationCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },

  photoWithLoader: {
    position: 'relative',
    height: imageHeight,
    width: imageWidth,
  },

  verificationImageContainer: {
    height: imageHeight,
    width: imageWidth,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  verificationImage: {
    height: imageHeight,
    width: imageWidth,
    maxWidth: '100%',
    objectFit: 'contain',
    backgroundColor: 'rgba(0,0,0,0.2)',
    border: '5px solid red',
  },

  hidden: {
    visibility: 'hidden',
  },

  loaderContainer: {
    position: 'absolute',
    top: 0,
    height: imageHeight,
    width: imageWidth,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  mediaName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '22px',
    borderRadius: '15px',
    backgroundColor: 'rgba(247, 247, 247, 1)',
    color: 'black',
    fontFamily: 'Poppins',
    fontSize: '9px',
    lineHeight: '14px',
    position: 'absolute',
    bottom: '-29px',
  },

  aiDecision: {
    width: '35px',
    height: '18px',
    borderRadius: '50px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '7px',
    left: '7px',
  },

  aiDecisionText: {
    marginTop: '2px',
    color: 'white',
    fontFamily: 'Poppins',
    fontSize: '8px',
    lineHeight: '14px',
  },
});
