import { useCallback } from 'react';
import generator from 'generate-password';
import { nanoid } from 'nanoid';

import { agencyRequest, authRequest, IdentityCredentials } from 'src/network';
import { getRealm } from 'src/utils/realm';
import { useAuth } from 'src/services/auth';
import { AgencyCommission } from 'src/types/agency';
import { OperatorLanguage } from 'src/types/operator';
import { useSnackbar } from 'src/utils/snackbar';

export type CreateAgencyPayload = {
  name: string;
  commission: AgencyCommission;
  language: OperatorLanguage;
};

const makeTechName = (name: string) =>
  name
    .split(' ')
    .filter((part) => part.trim())
    .map((part) => part.toLowerCase())
    .join('-');

export const useCreateAgency = () => {
  const { showSuccessMessage, showErrorMessage } = useSnackbar();

  const { me } = useAuth();

  const createOperator = useCallback(
    async (payload: CreateAgencyPayload) => {
      const { name, commission, language } = payload;

      const agencyTechName = makeTechName(name);

      try {
        const registerData: IdentityCredentials = {
          email: `atolive1+${nanoid(6)}@gmail.com`,
          password: generator.generate({
            length: 10,
            numbers: true,
            symbols: true,
          }),
        };
        const { data: identity } = await authRequest.register(registerData);

        await agencyRequest.setAgencyRole(identity.id);
        await agencyRequest.setAgencyTechName(identity.id, agencyTechName);

        await agencyRequest.updateAgency(agencyTechName, {
          commission,
          language,
          isActive: true,
          realm: getRealm(me?.realm),
          name,
        });

        showSuccessMessage(
          `Agency ${name} successfully created \n ID: ${identity.id} \n Email: ${registerData.email}  \n Password: ${registerData.password}`,
        );

        return true;
      } catch (error) {
        showErrorMessage((error as Error)?.message || 'Bad request');

        return false;
      }
    },
    [showErrorMessage, showSuccessMessage, me?.realm],
  );

  return createOperator;
};
