import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useUser } from 'src/services/user/hooks';
import { userRequest } from 'src/network';
import { useUserModerationHistory } from 'src/services/moderation/hooks';
import { Gender, UserTagType } from 'src/types/user';
import { dialogsRequest, ApiMessage } from 'src/network/dialogs';
import { useAuth } from 'src/services/auth';
import { UserRoles } from 'src/types/user/roles';

import { ActionsContext, DataContext, DataContextType } from './contexts';
import { logError } from '../logger';

export { ActionsContext, DataContext };

export const RiskModerationProvider: React.FC = ({ children }) => {
  const { userId } = useParams<{ userId: string }>();
  const { me } = useAuth();
  const { userState, fetchUser } = useUser();
  const { moderationHistoryState, fetchModerationHistory } = useUserModerationHistory();
  const [userGender, setUserGender] = useState<Gender>();
  const [userTags, setUserTags] = useState<UserTagType[] | null>(null);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [suspiciousMessages, setSuspiciousMessages] = useState<ApiMessage[] | null>(null);
  const [hasEmailSubscription, setHasEmailSubscription] = useState<boolean | null>(null);
  const [actualSubscription, setActualSubscriptions] = useState<DataContextType['actualSubscription']>(null);
  const [balance, setBalance] = useState(0);
  const errorHandler = useCallback(
    (action: string, error: unknown) => {
      logError(action, { error, operatorId: me?.id, userId });
    },
    [userId, me?.id],
  );
  const isFinanceOrAdmin = useMemo(() => {
    const rolesSet = new Set(me?.roles || []);
    return rolesSet.has(UserRoles.FinanceOperator) || rolesSet.has(UserRoles.Supervisor);
  }, [me?.roles]);

  const updateUserTags = useCallback(() => userRequest.getUserTags(userId).then(setUserTags), [userId]);
  const updateEmailSubscription = useCallback(
    () => userRequest.checkEmailSubscription(userId).then(setHasEmailSubscription),
    [userId],
  );
  const updateUserRoles = useCallback(() => userRequest.roles.getAll(userId).then(setUserRoles), [userId]);
  const updateModerationHistory = useCallback(() => {
    fetchModerationHistory(userId).catch((error) => errorHandler('Load Moderation History', error));
  }, [errorHandler, fetchModerationHistory, userId]);
  const updateUserBalance = useCallback(() => userRequest.getBalance(userId).then(setBalance), [userId]);
  const updateSuspiciousMessages = useCallback(
    () => dialogsRequest.getSuspiciousMessages(userId).then(({ messages }) => setSuspiciousMessages(messages || [])),
    [userId],
  );
  const updateActualSubscription = useCallback(
    () =>
      userRequest
        .getSubscriptionDetails(userId)
        .then((subscriptions) => subscriptions?.[0] || null)
        .then(setActualSubscriptions),
    [userId],
  );

  const updateGender = useCallback(
    (gender: Gender) => {
      setUserGender(gender);
      updateModerationHistory();
    },
    [updateModerationHistory],
  );

  useEffect(() => {
    fetchUser(userId);
  }, [fetchUser, userId]);

  useEffect(() => {
    if (userState.error || !userState.value || userState.value.id !== userId) {
      return;
    }

    setUserGender(userState.value?.gender);
    updateModerationHistory();
    updateUserTags().catch((error) => errorHandler('Load User Tags', error));
    updateUserRoles().catch((error) => errorHandler('Load User Roles', error));
    updateEmailSubscription().catch((error) => errorHandler('Load Email Subs', error));
    updateUserBalance().catch((error) => errorHandler('Load User Balance', error));
    updateSuspiciousMessages().catch((error) => errorHandler('Load Suspicious Messages', error));

    if (isFinanceOrAdmin) {
      updateActualSubscription().catch((error) => errorHandler('Load Actual Sub', error));
    }
  }, [
    userState,
    fetchModerationHistory,
    updateUserBalance,
    updateEmailSubscription,
    updateModerationHistory,
    updateSuspiciousMessages,
    updateUserRoles,
    updateUserTags,
    updateActualSubscription,
    isFinanceOrAdmin,
    userId,
    errorHandler,
  ]);

  const dataValue = {
    userId,
    user: userState,
    userTags,
    userRoles,
    emailSubscription: hasEmailSubscription,
    moderationHistory: moderationHistoryState,
    gender: userGender,
    userBalance: balance,
    suspiciousMessages,
    actualSubscription,
  };
  const actionsValue = useMemo(
    () => ({
      updateUserRoles,
      updateEmailSubscription,
      updateModerationHistory,
      updateGender,
      updateUserBalance,
      updateUserTags,
      updateActualSubscription,
    }),
    [
      updateUserRoles,
      updateEmailSubscription,
      updateModerationHistory,
      updateGender,
      updateUserBalance,
      updateUserTags,
      updateActualSubscription,
    ],
  );

  return (
    <DataContext.Provider value={dataValue}>
      <ActionsContext.Provider value={actionsValue}>{children}</ActionsContext.Provider>
    </DataContext.Provider>
  );
};
