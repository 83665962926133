import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  searchInput: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid black',
    borderRadius: '10px',
    padding: '5px 10px',
    width: '100%',
  },

  searchIcon: {
    marginRight: '8px',
    fontSize: '16px',
  },

  inputField: {
    border: 'none',
    outline: 'none',
    flex: 1,
    fontSize: '16px',
  },
}));
