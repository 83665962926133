import React from 'react';

import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import { CloseIcon } from '@material-ui/data-grid';

import { ONCE } from 'src/types/once';
import { ModerationContentCategoriesPhoto } from 'src/components/Moderation/screens/UserModeration/once/components';
import { userCachedPhotoSize } from 'src/components/Moderation/utils/image-source';

import { useStyles } from './styles';

const selectedCategoriesFallback: Record<string, string> = {};

type Props = {
  handleClose: () => void;
  isDialogOpen: boolean;
  mediaSource: string;
  categoryLevels?: ONCE.ContentDecisionCategoryLevel[];
};

export const StopWordDescriptionPhotoDialog = (props: Props) => {
  const { mediaSource, handleClose, isDialogOpen, categoryLevels } = props;

  const mediaSourceFullSize = mediaSource?.replace(userCachedPhotoSize, '');

  const classes = useStyles();

  const selectedCategories =
    (isDialogOpen &&
      categoryLevels?.reduce<Record<string, string>>((acc, curr) => {
        acc[curr.category] = curr.level;

        return acc;
      }, {})) ||
    selectedCategoriesFallback;

  return (
    <Dialog fullScreen open={isDialogOpen} onClose={handleClose} PaperProps={{ className: classes.modalContainer }}>
      <DialogContent
        className={classes.modalContentContainer}
        onClick={(event) => {
          if (event.target === event.currentTarget) {
            handleClose();
          }
        }}
      >
        <div className={classes.modalLeftContainer}>
          <img className={classes.photo} src={mediaSourceFullSize} alt="moderation-content" />
        </div>
        <div className={classes.modalRightContainer}>
          <ModerationContentCategoriesPhoto selectedCategories={selectedCategories} />
        </div>

        <IconButton aria-label="close" onClick={handleClose} className={classes.modalIconContainer}>
          <CloseIcon className={classes.modalIcon} />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};
