import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { FormType, FormAction } from '../../types';
import { typesList, actionsList } from './constants';
import { SelectsContainer } from './styled';

type Props = {
  currentType: FormType;
  currentAction: FormAction;
  onTypeChange: (type: FormType) => void;
  onActionChange: (action: FormAction) => void;
};

type SelectEvent = React.ChangeEvent<{ value: unknown }>;

export const ModeSelect = (props: Props) => {
  const { currentType, currentAction, onTypeChange, onActionChange } = props;

  const handleTypeChange = (e: SelectEvent) => {
    onTypeChange(e.target.value as FormType);
  };

  const handleActionChange = (e: SelectEvent) => {
    onActionChange(e.target.value as FormAction);
  };

  return (
    <SelectsContainer>
      <Select fullWidth variant="outlined" value={currentAction} onChange={handleActionChange}>
        <MenuItem disabled>Select action</MenuItem>
        {actionsList.map(({ name, value }) => (
          <MenuItem key={value} value={value}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <Select fullWidth variant="outlined" value={currentType} onChange={handleTypeChange}>
        <MenuItem disabled>Select type</MenuItem>
        {typesList.map(({ name, value }) => (
          <MenuItem key={value} value={value}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </SelectsContainer>
  );
};
