import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { logError } from '../logger';
import { useModerationDetails } from './useModerationDetails';

type Payload = {
  error: unknown;
  gemsAmount?: number;
  creditType?: string;
};

export const useActionErrorHandler = () => {
  const { operatorId, userId } = useModerationDetails();
  const { enqueueSnackbar } = useSnackbar();

  const errorHandler = useCallback(
    (action: string, payload: Payload) => {
      enqueueSnackbar(`Action: ${action} - Error: ${payload.error}`, {
        variant: 'error',
        style: { whiteSpace: 'pre-line' },
        autoHideDuration: 10000,
      });

      logError(`Action: ${action}`, {
        operatorId,
        userId,
        ...payload,
      });
    },
    [enqueueSnackbar, operatorId, userId],
  );

  return errorHandler;
};
