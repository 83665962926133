import React from 'react';
import { Box, Paper, InputBase, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { useSearchByUserId } from '../../../hooks/useSearchByUserId';
import { useStyles } from '../styles';

export const SearchPanel = () => {
  const classes = useStyles();
  const { searchText, onTextChange, onSubmit } = useSearchByUserId();

  return (
    <Box className={classes.searchContainer}>
      <Paper component="form" className={classes.searchInputContainer} onSubmit={onSubmit}>
        <IconButton type="submit" className={classes.searchIconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          className={classes.searchInput}
          placeholder="Search"
          inputProps={{ 'aria-label': 'search' }}
          value={searchText}
          onChange={onTextChange}
        />
      </Paper>
    </Box>
  );
};
