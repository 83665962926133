import React, { PropsWithChildren } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Controller, Control, FieldValues, FieldName } from 'react-hook-form';

type Props<T extends FieldValues> = PropsWithChildren<{
  name: FieldName<T>;
  label: string;
  control: Control<T>;
  variant?: 'filled' | 'outlined' | 'standard';
  size?: 'small' | 'medium';
  error?: boolean;
  disabled?: boolean;
}>;

const fullWidthStyle = { width: '100%' };

const ReactHookFormSelect = <T extends FieldValues>(props: Props<T>) => {
  const { error, disabled, name, label, control, children, variant, size } = props;

  const labelId = `${name}-label`;

  return (
    <FormControl variant={variant} size={size} style={fullWidthStyle}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        as={
          <Select disabled={disabled} labelId={labelId} label={label}>
            {children}
          </Select>
        }
        name={name}
        error={error}
        control={control}
      />
    </FormControl>
  );
};
export default ReactHookFormSelect;
