import { Http } from 'src/network/http';
import { AgenciesDto, GetAgenciesQueryParams, UpdateAgencyPayload, UserTagsAgencyDto } from './types';
import { adapter } from './adapter';

const getAll = (params: GetAgenciesQueryParams) => {
  return Http.shared()
    .instance.get<AgenciesDto>('/multichat/agencies', { params })
    .then(adapter.getAll);
};

const getAgencyName = (userId: string) => {
  return Http.shared().instance.get<UserTagsAgencyDto>(`/users/${userId}/tags/agency`).then(adapter.getAgencyName);
};

const updateAgency = (agencyTechName: string, payload: UpdateAgencyPayload) => {
  return Http.shared()
    .instance.put<void>(`/multichat/agencies/${agencyTechName}`, payload)
    .then((response) => response.data);
};

const setAgencyRole = (userId: string) => {
  return Http.shared()
    .instance.post<void>(`/users/${userId}/tags/roles/agency`)
    .then((response) => response.data);
};

const setAgencyTechName = (userId: string, agencyTechName: string) => {
  return Http.shared()
    .instance.post<void>(`/users/${userId}/tags/agency/${agencyTechName}`)
    .then((response) => response.data);
};

export const agencyRequest = {
  getAll,
  getAgencyName,
  updateAgency,
  setAgencyRole,
  setAgencyTechName,
};
