import { useCallback, useState } from 'react';

import { onceModerationRequest } from 'src/network/moderation/once';
import { OnceModerationGroup } from 'src/network/moderation/once/types';

import { useActionsContext } from './useContextHooks';
import { useActionErrorHandler } from './useActionErrorHandler';
import { useModerationDetails } from './useModerationDetails';
import { useAnnalsEventLog, AnnalsLogEvent } from './useAnnalsEventLog';
import { useUserGroups } from './useUserGroups';

export const useAnimatorsParadiseAction = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { userId, operatorRoles } = useModerationDetails();
  const { updateUserTags } = useActionsContext();
  const errorHandler = useActionErrorHandler();
  const { isInAnimatorsGroup, currentGroup } = useUserGroups();
  const logEvent = useAnnalsEventLog();

  const setAnimatorsGroup = useCallback(() => {
    const event: AnnalsLogEvent = {
      action: 'approved-for-animators',
      groupAfter: 'animators',
      groupBefore: currentGroup,
    };

    setIsLoading(true);
    onceModerationRequest
      .setOnceModerationGroup(userId, OnceModerationGroup.Unapproved)
      .then(updateUserTags)
      .then(() => logEvent(event))
      .catch((error) => errorHandler(OnceModerationGroup.Unapproved, { error }))
      .finally(() => setIsLoading(false));
  }, [currentGroup, userId, updateUserTags, logEvent, errorHandler]);

  return {
    available: operatorRoles.isProfileOrAdmin,
    isInAnimatorsGroup,
    isLoading,
    disabled: isInAnimatorsGroup,
    setAnimatorsGroup,
  };
};
