import React from 'react';

import { MediaModerationHistory } from 'src/components/Moderation/views/media-moderation-history';
import { useModerationDetails } from '../hooks/useModerationDetails';

const noop = () => () => undefined;

export const UserMediaContent = () => {
  const { userId } = useModerationDetails();

  if (!userId) {
    return null;
  }

  return <MediaModerationHistory userId={userId} onMediaPress={noop} clickable={false} />;
};
