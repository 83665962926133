const categories = {
  terrorism: 'Calls for terrorism or extremism',
  contactInfo: 'Contact information',
  offensiveLang: 'Hate speech, discriminatory, offensive language',
  obsceneLang: 'Obscene language',
  promoPolitic: 'Promotion of political views/religious belief',
  promoEscort: 'Promotion of prostitution, escort services, sex trafficking, paid dating',
  "stop-words": 'Stop words',
};

export const useModerationContentCategoriesText = () => {
  return {
    categories,
  };
};
