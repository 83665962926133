import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: 'fit-content',
    borderRadius: '100px',
    padding: '0px 12px 4px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});
