import { makeStyles, Theme } from '@material-ui/core';

const buttonBgHelper = (color: string, backgroundColor: string) => ({
  color,
  backgroundColor,
  '&:hover': {
    color,
    backgroundColor,
    opacity: 0.8,
  },
  '&.Mui-disabled': {
    color,
    backgroundColor,
    opacity: 0.4,
  },
});

export const useGenderStyles = makeStyles<
  Theme,
  {
    isMan?: boolean;
  }
>({
  genderButton: {
    color: 'white',
    textTransform: 'none',
    cursor: 'pointer',
    width: 107,
    borderRadius: '12px',
    height: '45px',
    fontSize: '16px',
    backgroundColor: (props) => (props.isMan ? '#40A3FF' : '#FF4FCE'),
    '&:hover': {
      backgroundColor: (props) => (props.isMan ? '#40A3FF' : '#FF4FCE'),
      opacity: 0.8,
    },
  },
});

export const useStyles = makeStyles({
  // not found
  containerCentred: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notFoundTitle: {
    fontSize: 40,
    color: '#020D3D',
    opacity: 0.5,
    fontWeight: 700,
  },
  // layout
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: 'calc(100dvh - 70px)',
    bottom: '-1px',
    left: 0,
    position: 'fixed',
    overflowY: 'auto',
    width: '100%',
  },
  header: {
    position: 'sticky',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FAFAFA',
    zIndex: 1,
  },
  contentWrapper: { flex: 1, paddingTop: 16, paddingBottom: 16 },
  contentLeft: {
    height: '100%',
    padding: 16,
  },
  contentRight: {
    height: '100%',
    padding: 16,
  },
  contentFooter: {
    backgroundColor: '#FAFAFA',
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 'auto',
    position: 'sticky',
    bottom: 0,
    // height: 118,
    minHeight: 118,
  },

  //   search panel
  searchContainer: {},
  searchInputContainer: {
    width: '280px',
    height: '36px',
    borderColor: 'transparent',
    borderRadius: '10px',
    backgroundColor: '#F2F2F2',
    boxShadow: 'none',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    left: 0,
    right: 0,
  },
  searchIconButton: {
    padding: 10,
  },
  searchInput: {
    flex: 1,
  },

  // user profile
  userProfile: {
    marginTop: 30,
    color: '#26334D',
  },

  userTitle: {
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '48px',
    marginBottom: 30,
  },

  userText: {
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: 30,
  },

  userId: {
    fontSize: '12px',
    lineHeight: '18px',
    color: '#A8A8A8',
  },

  sectionContainer: {
    color: '#26334D',
    marginTop: 56,
  },
  sectionTitle: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    marginBottom: 16,
  },
  sectionText: {
    fontSize: '12px',
    lineHeight: '18px',
    marginBottom: 8,
  },

  linkButton: {
    fontSize: '12px',
    lineHeight: '18px',
    color: '#199EFF',
    padding: 0,
    backgroundColor: 'transparent',
    textTransform: 'none',
    minWidth: 'unset',
    fontWeight: 400,
  },

  // actions panel
  actionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: 4,
  },

  checkedIcon: {
    fontSize: 18,
    marginRight: 5,
  },

  moderationButton: {
    height: 78,
    padding: '0 24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    lineHeight: '28px',
    borderRadius: '8px',
    textTransform: 'none',
    backgroundColor: '#ffffff',
  },
  deleteAction: {
    ...buttonBgHelper('#ffffff', '#883FFF'),
  },
  scamAction: {
    ...buttonBgHelper('#ffffff', '#EE654B'),
  },
  animatorsParadiseAction: {
    ...buttonBgHelper('#ffffff', '#FEAB30'),
  },
  cancelPremiumAction: {
    ...buttonBgHelper('#ffffff', '#26334D'),
  },

  moderationLabel: {
    pointerEvents: 'none',
  },

  // modal
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins',
  },
  modalContent: {
    width: 376,
    backgroundColor: 'white',
    alignSelf: 'center',
    padding: '32px',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    boxShadow: `0px 0px 1px 0px #0000000A,
0px 4px 8px 0px #0000000A,
0px 16px 24px 0px #0000000A,
0px 24px 32px 0px #0000000A`,
  },
  modalTitle: {
    color: '#26334D',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '30px',
  },
  modalText: {
    marginTop: '8px',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#26334D',
  },
  modalButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    gap: 16,
  },
  modalButton: {
    height: 52,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    textTransform: 'none',
    borderRadius: 8,
    boxShadow: `0px 0px 1px 0px #0000000A,
0px 4px 8px 0px #0000000A,
0px 16px 24px 0px #0000000A,
0px 24px 32px 0px #0000000A`,
  },
  modalInput: {
    height: 44,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
    borderRadius: 8,
    border: '1px solid #EDEDED',
    paddingLeft: 16,
    '&:placeholder': {
      color: '#A8A8A8',
    },
  },
  modalAction: {
    ...buttonBgHelper('#ffffff', '#EE654B'),
  },
  modalCancel: {
    backgroundColor: '#ffffff',
  },
  gemsModalContent: {
    padding: '48px 68px',
  },
  gemsModalButtonContainer: {
    marginTop: 32,
  },
  modalGemsSecondaryAction: {
    ...buttonBgHelper('#26334D', '#FEAB30'),
  },
  modalGemsAction: {
    ...buttonBgHelper('#ffffff', '#26334D'),
  },
});
