import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useChangeOperatorActiveStatus, useOperators } from 'src/services/operator/hooks';
import { FormAction } from 'src/components/Multichat/operators-and-agencies-managing/types';

import { SubmitButton } from '../components/submit-button';
import { AutocompleteInput } from '../components/autocomplete-input';
import { Form } from '../components/styled';

import { validationSchema, defaultValues } from './schema';

type Props = {
  type: FormAction.Deactivate | FormAction.Reactivate;
};

export const ChangeOperatorActiveStatusForm = (props: Props) => {
  const { type } = props;

  const changeOperatorActiveStatus = useChangeOperatorActiveStatus();
  const { operators, loading, fetchOperators } = useOperators(type === FormAction.Deactivate);

  const { control, handleSubmit, errors, formState, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleFormSubmit = handleSubmit(async ({ operatorId }) => {
    const success = await changeOperatorActiveStatus({
      operatorId,
      isActive: type === 'reactivate',
    });
    if (success) {
      reset();
      fetchOperators();
    }
  });

  const operatorIds = useMemo(() => operators.map((op) => op.operatorId), [operators]);

  return (
    <Form onSubmit={handleFormSubmit}>
      <AutocompleteInput
        name="operatorId"
        label="Operator id"
        control={control}
        errors={errors}
        loading={loading}
        options={operatorIds}
      />
      <SubmitButton title={`${type} operator`} submitting={formState.isSubmitting} />
    </Form>
  );
};
