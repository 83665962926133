import { OperatorCountries, OperatorCities, OperatorCommission, OperatorLanguage } from 'src/types/operator';

export const DEFAULT_LANGUAGE: OperatorLanguage = 'en';

type OperatorLocationConfig = {
  country: OperatorCountries;
  latitude: number;
  longitude: number;
  allowedForUdates: boolean;
  language: OperatorLanguage;
};

export const operatorLocations: Record<OperatorCities, OperatorLocationConfig> = {
  Denver: {
    country: 'US',
    latitude: 39.7392,
    longitude: -104.9903,
    allowedForUdates: true,
    language: 'en',
  },
  'New-York': {
    country: 'US',
    latitude: 40.713051,
    longitude: -74.007233,
    allowedForUdates: false,
    language: 'en',
  },
  Hamburg: {
    country: 'DE',
    longitude: 9.9937,
    latitude: 53.5511,
    allowedForUdates: true,
    language: 'de',
  },
  London: {
    country: 'UK',
    longitude: -0.118092,
    latitude: 51.509865,
    allowedForUdates: false,
    language: 'en',
  },
  Wellington: {
    country: 'NZ',
    longitude: 174.777969,
    latitude: -41.276825,
    allowedForUdates: false,
    language: 'en',
  },
  Copenhagen: {
    country: 'DK',
    longitude: 12.568337,
    latitude: 55.676098,
    allowedForUdates: false,
    language: 'en',
  },
  Bern: {
    country: 'CH',
    longitude: 7.444608,
    latitude: 46.947922,
    allowedForUdates: false,
    language: 'en',
  },
  Paris: {
    country: 'FR',
    longitude: 2.3522219,
    latitude: 48.856614,
    allowedForUdates: true,
    language: 'fr',
  },
  Brussels: {
    country: 'BE',
    longitude: 4.34878,
    latitude: 50.85045,
    allowedForUdates: false,
    language: 'en',
  },
  Madrid: {
    country: 'ES',
    latitude: 40.4168,
    longitude: 3.7038,
    allowedForUdates: true,
    language: 'es',
  },
};

export const operatorCities = Object.keys(operatorLocations) as OperatorCities[];

export const validCommissions: OperatorCommission[] = [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07];

export const defaultCommission = validCommissions[0];
