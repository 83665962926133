import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Button, Box, FormControl, OutlinedInput, InputAdornment, Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

import { useModerationContentCount } from 'src/services/moderation/hooks';
import { ModerationContentCountResponse } from 'src/network/moderation/types';
import { Layout } from 'src/components/App/views/Layout';
import { useAuth } from 'src/services/auth';
import { UserRoles } from 'src/types/user/roles';
import { useKeyboardEventListener } from 'src/components/Moderation/utils/keyboard';
import { Product } from 'src/types/product';
import { useIsAdministrator } from 'src/services/user/hooks';

import { useStyles } from './styles';

export const StartModerationProcessScreen = () => {
  const { me } = useAuth();
  const [searchText, setSearchText] = useState('');
  const classes = useStyles();
  const isAdministrator = useIsAdministrator();
  const isCustomerSupport = useMemo(() => (me?.roles || []).includes(UserRoles.CustomerSupport), [me]);
  const isOperator = useMemo(() => (me?.roles || []).includes(UserRoles.UsersOperator), [me]);
  const history = useHistory();
  const udatesProduct = useMemo(() => me?.realm === Product.Once, [me]);
  const shouldLoadModerationCount = useMemo(() => isOperator || isAdministrator, [isAdministrator, isOperator]);
  const { contentCountState, fetchContentCount } = useModerationContentCount(udatesProduct, shouldLoadModerationCount);
  const { count: usersToModerateCount = 0 } = useMemo(
    () => contentCountState?.value || ({} as ModerationContentCountResponse),
    [contentCountState?.value],
  );

  const onTextChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);

  useEffect(() => {
    if (shouldLoadModerationCount) {
      fetchContentCount();
    }
  }, [shouldLoadModerationCount, fetchContentCount]);

  const keyPressListener = useCallback(
    (event: KeyboardEvent) => {
      if (
        (isAdministrator || isCustomerSupport) &&
        searchText.length > 0 &&
        !event.defaultPrevented &&
        event.key === 'Enter'
      ) {
        history.push(`/moderation/user/${searchText}`);
      }
    },
    [history, isAdministrator, isCustomerSupport, searchText],
  );

  useKeyboardEventListener(keyPressListener);

  return (
    <Layout containerSize="lg">
      <Box className={classes.container}>
        {(isOperator || isAdministrator) && (
          <Button component={Link} to="/moderation/content" className={classes.button}>
            Start moderation {usersToModerateCount ? `(${usersToModerateCount})` : ''}
          </Button>
        )}
        {(isAdministrator || isCustomerSupport) && (
          <>
            {(isOperator || isAdministrator) && <Typography className={classes.orText}>or</Typography>}
            <FormControl variant="outlined" className={classes.formControl}>
              <OutlinedInput
                value={searchText}
                onChange={onTextChange}
                endAdornment={
                  searchText ? (
                    <InputAdornment position="end">
                      <Button component={Link} to={`/moderation/user/${searchText}`} className={classes.findButton}>
                        Find
                      </Button>
                    </InputAdornment>
                  ) : null
                }
                placeholder="Search by id"
                className={classes.textInput}
                labelWidth={0}
              />
            </FormControl>
          </>
        )}
      </Box>
    </Layout>
  );
};
