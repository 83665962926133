import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash';

import { Layout } from 'src/components/App/views/Layout';
import {
  DEFAULT_AGE,
  DEFAULT_GENDER_IDENTITY,
  DEFAULT_LOCATION_OPTIONS,
  DEFAULT_LOOKING_FOR,
  defaultFiltersState,
} from 'src/components/FlureFeed/constants';
import { Filter } from 'src/network/flure-feed/types';
import { useFeedUsers, useLocationsWithUsersCount, useMyLocationsWithUsersCount } from 'src/services/flure-feed/hooks';
import { flureFeedRequest } from 'src/network/flure-feed';
import { GenderIdentityPreferences } from 'src/types/user/preferences';

import { ActionButtons, PhonePreview, UserFilterParams, UserProfileParams } from './components';
import { locationsFormatter, LocationOption } from './utils';
import { useBackgroundTasks, useFeedNotifications, useFilters, useUserData } from './hooks';
import { useStyles } from './styles';

export const FlureFeedScreen = () => {
  const classes = useStyles();
  const [applyDisabled, setApplyDisabled] = useState(true);
  const {
    tempFilters,
    appliedFilters,
    selectedLookingForGenders,
    setSelectedLookingForGenders,
    setAppliedFilters,
    onLocationFilterChange,
    onAgeFilterChange,
    resetFilters,
  } = useFilters();

  const {
    operatorId,
    myAge,
    setMyAge,
    myGenderIdentity,
    genderIdentities,
    setGenderIdentities,
    myLocation,
    onMyLocationChange,
    onAgeChange,
    updateUser,
  } = useUserData();

  const { locations, fetchLocations } = useLocationsWithUsersCount();
  const { myLocations, fetchMyLocations } = useMyLocationsWithUsersCount();
  const { users, loading: usersLoading, fetchUsers, resetUsersState } = useFeedUsers();
  const { showApplyFiltersErrorNotification, showNoIdErrorNotification } = useFeedNotifications();
  const locationOptions = useMemo(() => {
    const formattedLocations = locations ? locationsFormatter(locations) : [];
    return DEFAULT_LOCATION_OPTIONS.concat(formattedLocations);
  }, [locations]);
  const myLocationOptions = useMemo(() => (myLocations ? locationsFormatter(myLocations) : []), [myLocations]);
  const isResetPressed = useRef(false);
  const [myCurrentLocation, setMyCurrentLocation] = useState<LocationOption | null>(null);

  const loadMore = useCallback(() => {
    fetchUsers({ filters: appliedFilters, myGenderIdentity });
  }, [fetchUsers, myGenderIdentity, appliedFilters]);

  const onApplyPress = useCallback(async () => {
    if (operatorId) {
      try {
        resetUsersState();
        await updateUser();
        await flureFeedRequest.saveSearchFilters(operatorId, tempFilters);
        fetchUsers({ filters: tempFilters, myGenderIdentity, reload: true });
        setAppliedFilters(tempFilters);
        setMyCurrentLocation(myLocation);
      } catch {
        showApplyFiltersErrorNotification();
      }
    } else {
      showNoIdErrorNotification();
    }
  }, [
    operatorId,
    myLocation,
    resetUsersState,
    updateUser,
    tempFilters,
    myGenderIdentity,
    fetchUsers,
    setAppliedFilters,
    showApplyFiltersErrorNotification,
    showNoIdErrorNotification,
  ]);

  const onResetPress = useCallback(async () => {
    resetFilters();
    setGenderIdentities([DEFAULT_GENDER_IDENTITY]);
    setSelectedLookingForGenders([DEFAULT_LOOKING_FOR]);
    setMyAge(DEFAULT_AGE);
    onMyLocationChange(myLocationOptions[0]);
    isResetPressed.current = true;
    setApplyDisabled(true);
  }, [
    myLocationOptions,
    onMyLocationChange,
    resetFilters,
    setGenderIdentities,
    setMyAge,
    setSelectedLookingForGenders,
  ]);

  useBackgroundTasks({
    operatorId,
    myLocation,
    myLocationOptions,
    myGenderIdentity,
    isResetPressed,
    fetchLocations,
    fetchMyLocations,
    onMyLocationChange,
    onApply: onApplyPress,
  });

  useEffect(() => {
    if (
      isEqual(tempFilters, defaultFiltersState) &&
      isEqual(appliedFilters, defaultFiltersState) &&
      myGenderIdentity === DEFAULT_GENDER_IDENTITY &&
      myAge === DEFAULT_AGE &&
      myLocation &&
      myLocation.value === myCurrentLocation?.value
    ) {
      setApplyDisabled(true);
    } else {
      setApplyDisabled(false);
    }
  }, [
    appliedFilters,
    myAge,
    myCurrentLocation?.value,
    myGenderIdentity,
    myLocation,
    selectedLookingForGenders,
    tempFilters,
  ]);

  if (!locations) {
    return null;
  }

  return (
    <Layout containerSize="xl">
      <div className={classes.container}>
        <UserProfileParams
          age={myAge}
          genderIdentities={genderIdentities}
          setGenderIdentities={setGenderIdentities}
          myLocationOptions={myLocationOptions}
          onMyLocationChange={onMyLocationChange}
          onAgeChange={onAgeChange}
          myLocation={myLocation?.value}
          searchLocations={fetchMyLocations}
        />
        <div className={classes.middleContainer}>
          <PhonePreview
            users={users}
            loading={usersLoading}
            loadMore={loadMore}
            locationFilter={appliedFilters[Filter.Location]!}
            showInvites={myGenderIdentity === GenderIdentityPreferences.Woman}
          />
        </div>
        <div className={classes.rightContainer}>
          <UserFilterParams
            filters={tempFilters}
            selectedLookingForGenders={selectedLookingForGenders}
            setSelectedLookingForGenders={setSelectedLookingForGenders}
            locationOptions={locationOptions}
            onLocationFilterChange={onLocationFilterChange}
            onAgeFilterChange={onAgeFilterChange}
          />
          <ActionButtons onApplyPress={onApplyPress} onResetPress={onResetPress} applyDisabled={applyDisabled} />
        </div>
      </div>
    </Layout>
  );
};
