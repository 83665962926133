import { useCallback } from 'react';
import { annalsRequest } from 'src/network/annals';

import { UserGroup } from '../types';
import { useModerationDetails } from './useModerationDetails';

export type AnnalsLogEvent = {
  action:
    | 'deleted'
    | 'scam'
    | 'unscam'
    | 'approved-for-animators'
    | 'approved-for-all'
    | 'credits-gems'
    | 'added-email-notifications'
    | 'removed-email-notifications'
    | 'canceled-subscription';
  gemsAmount?: number;
  creditType?: string;
  groupAfter?: UserGroup;
  groupBefore?: UserGroup;
  sku?: string;
};

export const useAnnalsEventLog = () => {
  const { operatorId, userId } = useModerationDetails();
  const log = useCallback(
    (event: AnnalsLogEvent) => {
      annalsRequest.logRiskManagmentEvents(operatorId, {
        operatorId,
        userId,
        ...event,
      });
    },
    [operatorId, userId],
  );
  return log;
};
