import { activityReportRequest } from './activity';
import { commissionReportsRequest } from './commissions';
import { kpiReportRequest } from './kpi';
import { messagesReportsRequest } from './messages';

export const reportRequest = {
  activity: activityReportRequest,
  commissions: commissionReportsRequest,
  kpi: kpiReportRequest,
  messages: messagesReportsRequest,
};
