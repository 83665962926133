import React from 'react';
import { Control, Controller, FieldError, FieldErrors, FieldName, FieldValues } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { InputWrapper } from './styled';

type Props<TFieldValues extends FieldValues, TOption = any> = {
  loading?: boolean;
  options: TOption[];
  control: Control<TFieldValues>;
  name: FieldName<TFieldValues>;
  label: string;
  errors: FieldErrors<TFieldValues>;
  getOptionLabel?: (option: TOption) => string;
  getOptionSelected?: (option: TOption, selected: TOption) => boolean;
};

export const AutocompleteInput = <TFieldValues extends FieldValues, TOption = any>(
  props: Props<TFieldValues, TOption>,
) => {
  const { loading, options, control, name, label, errors, getOptionLabel, getOptionSelected } = props;

  return (
    <InputWrapper>
      <Controller
        render={(controlProps) => (
          <Autocomplete
            loading={loading}
            options={options}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            renderInput={(inputProps) => (
              <TextField
                {...inputProps}
                label={label}
                variant="outlined"
                size="small"
                error={!!errors[name]}
                helperText={(errors[name] as FieldError)?.message}
              />
            )}
            onChange={(_, item) => {
              controlProps.onChange(item);
            }}
            value={controlProps.value}
            disableClearable
          />
        )}
        name={name}
        control={control}
      />
    </InputWrapper>
  );
};
