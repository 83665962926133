import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { RiskModerationRoutes } from '../constants';

export const useSearchByUserId = () => {
  const [searchText, setSearchText] = useState('');
  const history = useHistory();
  const onTextChange = useCallback((event) => {
    const { value } = event.target;

    if (!value || +value) {
      setSearchText(value);
    }
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (searchText) {
        history.push(RiskModerationRoutes.User(searchText));
      }
    },
    [history, searchText],
  );

  return {
    searchText,
    onTextChange,
    onSubmit,
  };
};
