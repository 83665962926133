import { useCallback, useState } from 'react';

import { onceModerationRequest } from 'src/network/moderation/once';
import { OnceModerationGroup } from 'src/network/moderation/once/types';

import { useActionsContext } from './useContextHooks';
import { useActionErrorHandler } from './useActionErrorHandler';

import { useModerationDetails } from './useModerationDetails';
import { AnnalsLogEvent, useAnnalsEventLog } from './useAnnalsEventLog';
import { useUserGroups } from './useUserGroups';

export const useScamAction = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { userId, operatorRoles } = useModerationDetails();
  const { updateUserTags } = useActionsContext();
  const errorHandler = useActionErrorHandler();
  const { isInScamGroup, currentGroup } = useUserGroups();
  const logEvent = useAnnalsEventLog();

  const setScam = useCallback(() => {
    const event: AnnalsLogEvent = {
      action: 'scam',
      groupAfter: 'scam',
      groupBefore: currentGroup,
    };

    setIsLoading(true);
    onceModerationRequest
      .setOnceModerationGroup(userId, OnceModerationGroup.Scam)
      .then(updateUserTags)
      .then(() => logEvent(event))
      .catch((error) => errorHandler(OnceModerationGroup.Scam, { error }))
      .finally(() => setIsLoading(false));
  }, [currentGroup, userId, updateUserTags, logEvent, errorHandler]);

  return {
    available: operatorRoles.isProfileOrAdmin,
    scammed: isInScamGroup,
    isLoading,
    disabled: isInScamGroup,
    setScam,
  };
};
