import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>({
  moderationContentContainer: {
    backgroundColor: '#F2F2F2',
    borderRadius: '8px',
    borderColor: 'transparent',
    marginRight: 'auto',
    padding: '10px 12px',
    marginBottom: '10px',
  },

  moderationContentText: {
    color: '#6B6B6B',
    fontSize: '10px',
    fontWeight: 600,
  },
});
