import React from 'react';

import { RiskModerationProvider } from './provider';
import { RiskModerationUserLayout } from './views/risk-moderation-user-layout';

export const RiskModerationUserScreen = () => {
  return (
    <RiskModerationProvider>
      <RiskModerationUserLayout />
    </RiskModerationProvider>
  );
};
