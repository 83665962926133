import React from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';

import { useModerationContentCategoriesText } from 'src/components/Moderation/hooks/once';
import { useStyles } from './styles';

type Props = {
  selectedCategories: string[];
};

export const ModerationContentCategoriesText = (props: Props) => {
  const { selectedCategories } = props;

  const { categories } = useModerationContentCategoriesText();

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {Object.keys(categories).map((key) => {
        const isSelected = selectedCategories.includes(key);

        return (
          <div
            className={clsx([classes.itemContainer, isSelected && classes.itemSelectedContainer])}
            key={key}
          >
            <Typography className={clsx([classes.itemText, isSelected && classes.itemSelectedText])}>
              {categories[key as keyof typeof categories]}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};
