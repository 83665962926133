import React, { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useAuth } from 'src/services/auth';
import { UserRoles } from 'src/types/user/roles';

import { useStyles } from './styles';

const supportedRoles: [UserRoles, string][] = [
  [UserRoles.UsersOperator, 'Profile'],
  [UserRoles.FinanceOperator, 'Finance'],
  [UserRoles.CreditsOperator, 'Credits'],
];

const getRolesString = (roles: UserRoles[]) => {
  const rolesSet = new Set(roles);

  if (rolesSet.has(UserRoles.Supervisor)) {
    return 'Supervisor';
  }

  const resultRoles = supportedRoles.reduce<string[]>((result, [role, title]) => {
    if (rolesSet.has(role)) {
      result.push(title);
    }
    return result;
  }, []);

  return resultRoles.join(', ');
};

export const OperatorRolesPanel = () => {
  const { me } = useAuth();
  const roles = useMemo(() => getRolesString(me?.roles || []), [me]);
  const classes = useStyles();

  if (!roles) {
    return null;
  }

  return (
    <Box className={classes.container}>
      🕵️‍♀️ <Typography className={classes.text}>{roles}</Typography>
    </Box>
  );
};
