import React from 'react';
import Button from '@material-ui/core/Button';

import { Agency } from 'src/types/agency';

import * as S from './styled';

export type AgenciesAnimatorsListItem = Agency & {
  animatorsCount: number;
};

type Props = {
  list: AgenciesAnimatorsListItem[];
  loading: boolean;
  onChange: (agencyTechName: string) => void;
};

const headerColumns = ['Agency', 'Agency Tech Name', 'Agency animators', 'Options'];

export const MultichatAgencyAnimatorsMappingTable = (props: Props) => {
  const { list, loading, onChange } = props;

  const renderRows = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan={3}>Loading...</td>
        </tr>
      );
    }

    if (!list.length) {
      return (
        <tr>
          <td colSpan={3}>Empty</td>
        </tr>
      );
    }

    return list.map(({ name, techName, animatorsCount }) => (
      <tr key={techName}>
        <S.TableBodyCell>{name}</S.TableBodyCell>
        <S.TableBodyCell>{techName}</S.TableBodyCell>
        <S.TableBodyCell>{animatorsCount}</S.TableBodyCell>
        <S.TableBodyCell>
          <Button type="button" variant="outlined" size="small" onClick={() => onChange(techName)}>
            Edit
          </Button>
        </S.TableBodyCell>
      </tr>
    ));
  };

  return (
    <S.Table>
      <thead>
        <tr>
          {headerColumns.map((columnName) => (
            <S.TableHeadCell key={columnName}>{columnName}</S.TableHeadCell>
          ))}
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </S.Table>
  );
};
