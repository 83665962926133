import { useMemo } from 'react';
import { getUserRolesMap } from 'src/components/config';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';
import { getUserAge } from 'src/utils/transform';

import { useDataContext } from './useContextHooks';

export const useModerationUser = () => {
  const { userId, user } = useDataContext();

  const loaded = user?.loading === false;

  return {
    deleted: !user?.value?.id,
    loaded,
    userId,
    name: user?.value?.name,
    age: getUserAge(user?.value?.birthday || ''),
    about: user?.value?.about,
    occupation: user?.value?.occupation,
  };
};

export const useModerationDetails = () => {
  const { me } = useAuth();
  const { userId } = useModerationUser();
  const operatorRoles = useMemo(() => {
    const roles = getUserRolesMap(me?.roles || []);

    return {
      isAdministrator: roles.isAdministrator,
      isProfileOrAdmin: roles.isAdministrator || roles.isOperator,
      isFinanceOrAdmin: roles.isAdministrator || roles.isFinanceOperator,
      isCreditsOrAdmin: roles.isAdministrator || roles.isCreditsOperator,
    };
  }, [me?.roles]);

  return {
    realm: me?.realm as Product,
    userId,
    operatorId: me?.id || '',
    operatorRoles,
  };
};
