import React from 'react';
import clsx from 'clsx';

import { Typography } from '@material-ui/core';

import { useModerationContentCategoriesPhoto } from 'src/components/Moderation/hooks/once';

import { useStyles } from './styles';

type Props = {
  selectedCategories: Record<string, string>;
};

export const ModerationContentCategoriesPhoto = (props: Props) => {
  const { selectedCategories } = props;

  const { categories } = useModerationContentCategoriesPhoto();

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {Object.keys(categories).map((key) => {
        const level = selectedCategories?.[key];

        return (
          <div
            className={clsx([
              classes.itemContainer,
              level === 'yellow' && classes.itemYellowContainer,
              level === 'red' && classes.itemRedContainer,
            ])}
            key={key}
          >
            <Typography
              className={clsx([
                classes.itemText,
                level === 'yellow' && classes.itemYellowText,
                level === 'red' && classes.itemRedText,
              ])}
            >
              {categories[key as keyof typeof categories]}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};
