import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  loader: {
    display: 'flex',
    justifyContent: 'center',
  },

  placeholderText: {
    textAlign: 'center',
    opacity: 0.5,
  },
});
