export enum FormType {
  Operator = 'operator',
  Agency = 'agency',
}

export enum FormAction {
  Create = 'create',
  ChangeCommission = 'changeCommission',
  Deactivate = 'deactivate',
  Reactivate = 'reactivate',
}
