import React, { useMemo, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';

import { useStyles } from '../styles';
import { useDataContext } from '../hooks/useContextHooks';

export const SuspiciousMessagesPanel = () => {
  const classes = useStyles();
  const { suspiciousMessages } = useDataContext();
  const [opened, setOpened] = useState(false);
  const showMoreButton = !!suspiciousMessages && suspiciousMessages.length > 2;
  const messagesToShow = useMemo(
    () => (showMoreButton && !opened ? suspiciousMessages?.slice(0, 2) : suspiciousMessages),
    [opened, showMoreButton, suspiciousMessages],
  );
  const buttonTitle = opened ? 'Hide' : 'Show All';

  if (!messagesToShow?.length) {
    return null;
  }

  return (
    <Box className={classes.sectionContainer}>
      <Typography component="h4" className={classes.sectionTitle}>
        Suspicious Messages
      </Typography>

      {messagesToShow.map((message) => (
        <Typography key={message.id} className={classes.sectionText}>
          🔴&nbsp;&nbsp;{message.text || ''}
        </Typography>
      ))}
      {showMoreButton && (
        <Button className={classes.linkButton} onClick={() => setOpened(!opened)}>
          {buttonTitle}
        </Button>
      )}
    </Box>
  );
};
