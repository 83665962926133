import React from 'react';

import { Operator, UpdatedOperatorState } from 'src/types/operator';
import { Agency } from 'src/types/agency';
import { MultichatAgencyOperatorsMappingTableRow } from './Row';
import * as S from './styled';

type Props = {
  loading: boolean;
  operators: Operator[];
  agencies: Agency[];
  onSave: (operatorId: string, data: UpdatedOperatorState) => void;
};

type Column = { name: string; width?: number };

const columns: Column[] = [
  { name: 'Operator ID' },
  { name: 'First name' },
  { name: 'Last name' },
  { name: 'Explicit', width: 60 },
  { name: 'Schedule' },
  { name: 'Agency' },
  { name: 'Tech Name' },
  { name: 'Options' },
];

export const MultichatAgencyOperatorsMappingTable = (props: Props) => {
  const { loading, operators, agencies, onSave } = props;

  const renderRows = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan={5}>Loading</td>
        </tr>
      );
    }

    if (!operators.length) {
      return (
        <tr>
          <td colSpan={5}>Empty</td>
        </tr>
      );
    }

    return operators.map((operator) => (
      <MultichatAgencyOperatorsMappingTableRow
        key={operator.operatorId}
        {...operator}
        agencies={agencies}
        onSave={onSave}
      />
    ));
  };

  return (
    <S.Table>
      <thead>
        <tr>
          {columns.map((column) => (
            <S.TableHeadCell
              key={column.name}
              // @ts-expect-error the width prop actually exists on the <th /> element
              width={column.width}
            >
              {column.name}
            </S.TableHeadCell>
          ))}
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </S.Table>
  );
};
