import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    width: '337px',
    height: 'fit-content',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '14px',
    backgroundColor: 'white',
    padding: '16px',
    position: 'relative',
    overflow: 'hidden',
  },

  topRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },

  filterTitle: {
    fontSize: '24px',
    lineHeight: '36px',
    color: '#B782F0',
  },

  inputContainer: {
    marginBottom: '10px',
  },

  optionsContainer: {
    overflow: 'scroll',
    height: '329px',
    padding: '8px 0',
    marginBottom: '137px',
  },

  buttonsContainer: {
    position: 'absolute',
    width: '100%',
    bottom: '0px',
    left: '0px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    padding: '21px',
    borderTop: '1px solid rgba(204, 204, 204, 0.3)',
    backgroundColor: 'white',
  },
});
