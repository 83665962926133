import React from 'react';

import { useStyles } from './styles';

type Props = {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const SearchInput = React.memo(({ onChange }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.searchInput}>
      <span className={classes.searchIcon}>🔍</span>
      <input type="text" placeholder="" onChange={onChange} className={classes.inputField} />
    </div>
  );
});
