import { useCallback } from 'react';

import { agencyRequest } from 'src/network';
import { getRealm } from 'src/utils/realm';
import { useAuth } from 'src/services/auth';
import { AgencyCommission } from 'src/types/agency';
import { useSnackbar } from 'src/utils/snackbar';

export type ChangeAgencyCommissionPayload = {
  name: string;
  techName: string;
  commission: AgencyCommission;
};

export const useChangeAgencyCommission = () => {
  const { showErrorMessage, showSuccessMessage } = useSnackbar();
  const realm = useAuth().me?.realm;

  const changeAgencyCommission = useCallback(
    async (payload: ChangeAgencyCommissionPayload) => {
      const { techName, name, commission } = payload;

      try {
        await agencyRequest.updateAgency(techName, {
          commission,
          realm: getRealm(realm),
        });

        showSuccessMessage(`Agency ${name} commission has been changed`);

        return true;
      } catch (error) {
        showErrorMessage((error as Error)?.message || 'Bad request');

        return false;
      }
    },
    [showErrorMessage, showSuccessMessage, realm],
  );

  return changeAgencyCommission;
};
