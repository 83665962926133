import { useCallback } from 'react';

import { useSnackbar } from 'src/utils/snackbar';
import { operatorRequest } from 'src/network';

export type ChangeOperatorActiveStatusPayload = {
  operatorId: string;
  isActive: boolean;
};

export const useChangeOperatorActiveStatus = () => {
  const { showErrorMessage, showSuccessMessage } = useSnackbar();

  const changeOperatorActiveStatus = useCallback(
    async (payload: ChangeOperatorActiveStatusPayload) => {
      const { operatorId, isActive } = payload;

      try {
        await operatorRequest[isActive ? 'deactivate' : 'activate'](operatorId);

        showSuccessMessage(`Account ${operatorId} has been ${isActive ? 'reactivated' : 'deactivated'}`);

        return true;
      } catch (error) {
        showErrorMessage((error as Error)?.message || 'Bad request');

        return false;
      }
    },
    [showErrorMessage, showSuccessMessage],
  );

  return changeOperatorActiveStatus;
};
