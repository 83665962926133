import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '30px',
  },

  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
  },

  titleText: {
    fontFamily: 'SFCompactDisplay',
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 400,
    color: '#878E9C',
    opacity: 0.5,
  },

  valueText: {
    fontFamily: 'SFCompactDisplay',
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 700,
    color: '#878E9C',
  },
});
