import React, { ComponentProps, useCallback, useState } from 'react';
import { Button } from '@material-ui/core';

import { useStyles } from '../styles';
import { CreditGemsModal } from './credit-gems-modal';

type ActionFnProp = ComponentProps<typeof CreditGemsModal>['onAction'];
type CreditGemsActionButtonProps = {
  disabled?: boolean;
  balance: number;
  hidden?: boolean;
  onAction: ActionFnProp;
};

export const CreditGemsActionButton: React.FC<CreditGemsActionButtonProps> = ({
  disabled,
  balance,
  hidden,
  onAction,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const onActionHandler = useCallback<ActionFnProp>(
    (type, amount) => {
      setIsOpen(false);
      onAction(type, amount);
    },
    [onAction],
  );
  const onCancel = useCallback(() => {
    setIsOpen(false);
  }, []);

  if (hidden) {
    return null;
  }

  return (
    <>
      <Button
        variant="contained"
        className={classes.moderationButton}
        onClick={() => setIsOpen(true)}
        disabled={disabled}
      >
        Credit
        <br />
        Gems
      </Button>

      {isOpen && <CreditGemsModal balance={balance} onAction={onActionHandler} onCancel={onCancel} />}
    </>
  );
};
