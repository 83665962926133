import React, { forwardRef } from 'react';

import { Typography } from '@material-ui/core';

import { ONCE } from 'src/types/once';

import { useStyles } from './styles';
import { OnceModerationContentCardText } from './card-text';
import { OnceModerationContentCardPhoto } from './card-photo';

type Props = {
  contentDecision: ONCE.ContentDecision[];
  isAdministrator: boolean;
  userId: string;
};

export const OnceModerationContent = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { contentDecision, isAdministrator, userId } = props;

  const classes = useStyles();

  if (!contentDecision.length) return null;

  return (
    <div ref={ref} className={classes.container}>
      <Typography component="h1" variant="h5" className={classes.titleText}>
        🚫 Deleted
      </Typography>

      <div className={classes.cardsContainer}>
        {contentDecision.map((decision) => {
          const { contentId, contentType, content, categories } = decision;

          switch (contentType) {
            case ONCE.ContentDecisionType.Text: {
              return (
                <OnceModerationContentCardText key={String(contentId)} content={content} categories={categories} />
              );
            }
            case ONCE.ContentDecisionType.Photo: {
              return (
                <OnceModerationContentCardPhoto
                  key={String(contentId)}
                  decision={decision}
                  userId={userId}
                  isAdministrator={isAdministrator}
                />
              );
            }
            default: {
              return null;
            }
          }
        })}
      </div>
    </div>
  );
});
