import { useCallback } from 'react';

import { operatorRequest } from 'src/network';
import { getRealm } from 'src/utils/realm';
import { useAuth } from 'src/services/auth';
import { OperatorCommission } from 'src/types/operator';
import { useSnackbar } from 'src/utils/snackbar';

export type ChangeOperatorCommissionPayload = {
  operatorId: string;
  commission: OperatorCommission;
};

export const useChangeOperatorCommission = () => {
  const { showErrorMessage, showSuccessMessage } = useSnackbar();
  const realm = useAuth().me?.realm;

  const changeOperatorCommission = useCallback(
    async (payload: ChangeOperatorCommissionPayload) => {
      const { operatorId, commission } = payload;

      try {
        await operatorRequest.updateCommission(operatorId, {
          commission,
          realm: getRealm(realm),
        });

        showSuccessMessage(`Account ${operatorId} commission has been changed`);

        return true;
      } catch (error) {
        showErrorMessage((error as Error)?.message || 'Bad request');

        return false;
      }
    },
    [showErrorMessage, showSuccessMessage, realm],
  );

  return changeOperatorCommission;
};
