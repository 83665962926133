export enum UserRoles {
  AgencyAdministrator = 'agency-administrator',
  Agency = 'agency',
  UsersOperator = 'users-operator',
  ModerationAdministrator = 'moderation-admin',
  Supervisor = 'supervisor',
  CustomerSupport = 'customer-support',
  ChatOperator = 'chat-operator',
  PromoCodesOperator = 'promocodes-operator',
  FinanceOperator = 'finance-operator',
  CreditsOperator = 'credits-operator',
  SearchOperator = 'search-operator',
  Suspended = 'suspended',
}
