import { object, string, mixed, SchemaOf } from 'yup';

import { Agency, AgencyCommission } from 'src/types/agency';
import { defaultCommission, agencyCommissions } from 'src/services/agency/config';

export type ChangeAgencyCommissionForm = {
  agency: Pick<Agency, 'name' | 'techName'>;
  commission: AgencyCommission;
};

export const defaultValues: ChangeAgencyCommissionForm = {
  agency: {
    name: '',
    techName: '',
  },
  commission: defaultCommission,
};

export const validationSchema: SchemaOf<ChangeAgencyCommissionForm> = object().shape({
  agency: object().shape({
    techName: string().required('Agency name is required'),
    name: string(),
  }) as SchemaOf<ChangeAgencyCommissionForm['agency']>,
  commission: mixed<AgencyCommission>()
    .default(defaultCommission)
    .oneOf(agencyCommissions)
    .required('Commission is required'),
});
