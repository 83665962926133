import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAgencies, useChangeAgencyActiveStatus } from 'src/services/agency/hooks';
import { FormAction } from 'src/components/Multichat/operators-and-agencies-managing/types';

import { SubmitButton } from '../components/submit-button';
import { AutocompleteInput } from '../components/autocomplete-input';
import { Form } from '../components/styled';

import { validationSchema, defaultValues } from './schema';

type Props = {
  type: FormAction.Deactivate | FormAction.Reactivate;
};

export const ChangeAgencyActiveStatusForm = (props: Props) => {
  const { type } = props;

  const changeAgencyActiveStatus = useChangeAgencyActiveStatus();
  const { agencies, loading, fetchAgencies } = useAgencies(type === FormAction.Deactivate);

  const { control, handleSubmit, errors, formState, reset } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleFormSubmit = handleSubmit(async ({ agency }) => {
    const success = await changeAgencyActiveStatus({
      name: agency.name,
      techName: agency.techName,
      isActive: type === 'reactivate',
    });
    if (success) {
      reset();
      fetchAgencies();
    }
  });

  return (
    <Form onSubmit={handleFormSubmit}>
      <AutocompleteInput
        name="agency"
        label="Agency name"
        control={control}
        errors={errors}
        loading={loading}
        options={agencies}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, selected) => option.techName === selected.techName}
      />
      <SubmitButton title={`${type} agency`} submitting={formState.isSubmitting} />
    </Form>
  );
};
