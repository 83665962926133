import { Product } from 'src/types/product';
import { UserRoles } from 'src/types/user/roles';

import { RiskModerationRoutes } from './RiskModeration/constants';

export const getProductsMap = (realm?: Product) => {
  return {
    udatesProduct: realm === Product.Once,
    flureProduct: realm === Product.Flure,
    rawProduct: realm === Product.RAW,
  };
};

export const getUserRolesMap = (roles: UserRoles[]) => {
  const rolesSet = new Set(roles);

  return {
    isAgencyAdministrator: rolesSet.has(UserRoles.AgencyAdministrator),
    isAdministrator: rolesSet.has(UserRoles.Supervisor),
    isModerationAdministrator: rolesSet.has(UserRoles.ModerationAdministrator),
    isOperator: rolesSet.has(UserRoles.UsersOperator),
    isCustomerSupport: rolesSet.has(UserRoles.CustomerSupport),
    isAgency: rolesSet.has(UserRoles.Agency),
    isChatOperator: rolesSet.has(UserRoles.ChatOperator),
    isPromoCodesOperator: rolesSet.has(UserRoles.PromoCodesOperator),
    isFinanceOperator: rolesSet.has(UserRoles.FinanceOperator),
    isCreditsOperator: rolesSet.has(UserRoles.CreditsOperator),
    isSearchOperator: rolesSet.has(UserRoles.SearchOperator),
  };
};

export type UserRolesMapType = ReturnType<typeof getUserRolesMap>;

export const getAppPages = (
  productMap: ReturnType<typeof getProductsMap>,
  rolesMap: ReturnType<typeof getUserRolesMap>,
) => {
  return {
    RiskModeration: {
      routes: RiskModerationRoutes,
      isAvailable: productMap.udatesProduct && (rolesMap.isAdministrator || rolesMap.isSearchOperator),
    },
  };
};
