import React from 'react';
import clsx from 'clsx';

import { LabelText } from 'src/components/common/flure';

import checkBoxEmptyIcon from './checkbox_empty.svg';
import checkBoxActiveIcon from './checkbox_active.svg';
import { useStyles } from './styles';

type Props = {
  text: string;
  isActive: boolean;
  disabled?: boolean;
  onClick: () => void;
};

export const CheckBox = React.memo((props: Props) => {
  const { text, isActive, onClick, disabled } = props;
  const classes = useStyles();

  return (
    <button
      type="button"
      className={clsx(classes.container, disabled && classes.defaultCursor)}
      onClick={onClick}
      disabled={disabled}
    >
      <img className={classes.icon} src={isActive ? checkBoxActiveIcon : checkBoxEmptyIcon} alt={isActive ? 'checkbox-on' : 'checkbox-off'} />
      <LabelText className={classes.text} text={text} />
    </button>
  );
});
