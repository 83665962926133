import { createContext } from 'react';

import { useUser } from 'src/services/user/hooks';
import { useUserModerationHistory } from 'src/services/moderation/hooks';
import { ActualSubscription, Gender, UserTagType } from 'src/types/user';
import { ApiMessage } from 'src/network/dialogs';

export type DataContextType = {
  userId: string;
  user: ReturnType<typeof useUser>['userState'] | null;
  userTags: UserTagType[] | null;
  userRoles: string[] | null;
  gender: Gender | undefined;
  emailSubscription: boolean | null;
  userBalance: number;
  moderationHistory: ReturnType<typeof useUserModerationHistory>['moderationHistoryState'] | null;
  suspiciousMessages: ApiMessage[] | null;
  actualSubscription: ActualSubscription | null;
};

export const DataContext = createContext<DataContextType>({
  userId: '',
  user: null,
  userTags: null,
  userRoles: null,
  emailSubscription: null,
  moderationHistory: null,
  gender: undefined,
  userBalance: 0,
  suspiciousMessages: null,
  actualSubscription: null,
});

export const ActionsContext = createContext({
  updateUserRoles: () => Promise.resolve(),
  updateUserTags: () => Promise.resolve(),
  updateEmailSubscription: () => Promise.resolve(),
  updateModerationHistory: () => {
    /* empty */
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  updateGender: (_gender: Gender) => {
    /* empty */
  },
  updateUserBalance: () => Promise.resolve(),
  updateActualSubscription: () => Promise.resolve(),
});
