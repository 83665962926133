import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;

export const InputWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;
