import React, { useState } from 'react';
import { Box, Button, Modal, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from '../styles';
import { ActionSwitchButton } from './action-button';

type DeleteActionButtonProps = {
  disabled?: boolean;
  deleted: boolean;
  hidden?: boolean;
  deleteUser: () => void;
};

export const DeleteActionButton: React.FC<DeleteActionButtonProps> = ({ deleted, disabled, hidden, deleteUser }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const onConfirm = () => {
    deleteUser();
    setIsOpen(false);
  };
  const onReject = () => {
    setIsOpen(false);
  };
  const onDelete = () => {
    if (!deleted) {
      setIsOpen(true);
    }
  };

  if (hidden) {
    return null;
  }

  return (
    <>
      <ActionSwitchButton
        className={classes.deleteAction}
        disabled={disabled}
        onLabel="Deleted"
        offLabel="Delete"
        isOn={!!deleted}
        onToggle={!deleted ? onDelete : undefined}
      />

      <Modal open={isOpen} className={classes.modal}>
        <Box className={classes.modalContent}>
          <Typography component="h4" className={classes.modalTitle}>
            Are you sure?
          </Typography>
          <Typography className={classes.modalText}>
            The user profile will be permanently deleted, but they can register again using the same email.
          </Typography>
          <Box className={classes.modalButtonContainer}>
            <Button variant="contained" className={clsx(classes.modalButton, classes.modalAction)} onClick={onConfirm}>
              Delete Profile
            </Button>
            <Button variant="contained" className={clsx(classes.modalButton, classes.modalCancel)} onClick={onReject}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
