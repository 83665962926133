import { object, string, mixed, bool, SchemaOf } from 'yup';

import { OperatorCommission, OperatorLanguage } from 'src/types/operator';
import { defaultCommission, validCommissions } from 'src/services/operator/config';
import { CreateOperatorPayload } from 'src/services/operator/hooks/use-create-operator';
import { operatorLanguages } from './config';

export const NO_AGENCY_VALUE = 'no_agency';

export type CreateOperatorForm = Omit<CreateOperatorPayload, 'city'> & {
  language: OperatorLanguage;
};

export const defaultValues: CreateOperatorForm = {
  firstName: '',
  lastName: '',
  commission: defaultCommission,
  isReadyForExplicit: true,
  isFreeMember: false,
  language: operatorLanguages[0],
  freeMemberAnimatorClientId: '',
  animatorCity: '',
  animatorCountry: '',
  agencyTechName: NO_AGENCY_VALUE,
};

export const validationSchema: SchemaOf<CreateOperatorForm> = object().shape({
  firstName: string().default(defaultValues.firstName).required('Operator first name is required'),
  lastName: string().default(defaultValues.lastName).required('Operator last name is required'),
  commission: mixed<OperatorCommission>()
    .default(defaultValues.commission)
    .oneOf(validCommissions)
    .required('Commission is required'),
  isReadyForExplicit: bool().default(defaultValues.isReadyForExplicit).required('isReadyForExplicit is required'),
  language: mixed<OperatorLanguage>()
    .default(defaultValues.language)
    .oneOf(operatorLanguages)
    .required('Language is required'),
  isFreeMember: bool().default(defaultValues.isFreeMember),
  freeMemberAnimatorClientId: string().when('isFreeMember', {
    is: true,
    then: (schema) => schema.required('Animator ID is required'),
  }),
  animatorCity: string().when('isFreeMember', {
    is: true,
    then: (schema) => schema.required('Animator City is required'),
  }),
  animatorCountry: string().when('isFreeMember', {
    is: true,
    then: (schema) => schema.required('Animator Country is required').length(2, 'Only 2 symbols (only abbreviation)'),
  }),
  agencyTechName: string(),
});
