import React, { ChangeEventHandler, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import { Operator, UpdatedOperatorState } from 'src/types/operator';
import { Agency } from 'src/types/agency';
import { AgencySelector } from 'src/components/Multichat/views/Agency/Selector';
import { ScheduleSelector } from 'src/components/Multichat/views/Schedule/Selector';
import * as S from '../styled';

type Props = Operator & {
  agencies: Agency[];
  onSave: (operatorId: string, data: UpdatedOperatorState) => void;
};

export const MultichatAgencyOperatorsMappingTableRow = ({
  operatorDbId,
  operatorId,
  firstName,
  lastName,
  agencyTechName,
  isReadyForExplicit,
  scheduleType,
  agencies,
  onSave,
}: Props) => {
  const initialState = {
    firstName,
    lastName,
    agencyTechName: agencyTechName || 'null',
    isReadyForExplicit,
    scheduleType,
  };

  const [formData, setFormData] = useState(initialState);

  const onTextFieldChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSelectChange = (fieldName: string) => (value: string) => {
    setFormData((prev) => ({ ...prev, [fieldName]: value }));
  };

  const onExplicitStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({ ...prev, isReadyForExplicit: event.target.checked }));
  };

  const [editableModeEnabled, setEditableMode] = useState(false);

  const startOperatorEditing = () => {
    setEditableMode(true);
    setFormData(initialState);
  };

  const cancelOperatorEditing = () => {
    setEditableMode(false);
  };

  const finishOperatorEditing = () => {
    setEditableMode(false);
    onSave(operatorId, formData);
  };

  const selectedAgency = useMemo(() => agencies.find((agency) => agency.techName === formData.agencyTechName), [
    agencies,
    formData.agencyTechName,
  ]);

  if (editableModeEnabled) {
    return (
      <tr>
        <S.TableBodyCell>{operatorDbId}</S.TableBodyCell>
        <S.TableBodyCell>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            size="small"
            value={formData.firstName}
            name="firstName"
            onChange={onTextFieldChange}
          />
        </S.TableBodyCell>
        <S.TableBodyCell>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            size="small"
            value={formData.lastName}
            name="lastName"
            onChange={onTextFieldChange}
          />
        </S.TableBodyCell>
        <S.TableBodyCell width={60}>
          <Checkbox checked={formData.isReadyForExplicit} onChange={onExplicitStatusChange} />
        </S.TableBodyCell>
        <S.TableBodyCell>
          <ScheduleSelector value={formData.scheduleType} onChange={onSelectChange('scheduleType')} />
        </S.TableBodyCell>
        <S.TableBodyCell>
          <AgencySelector
            agencies={agencies}
            value={formData.agencyTechName}
            onChange={onSelectChange('agencyTechName')}
          />
        </S.TableBodyCell>
        <S.TableBodyCell>{formData.agencyTechName}</S.TableBodyCell>
        <S.TableBodyCell>
          <Button type="button" variant="contained" color="primary" size="small" onClick={finishOperatorEditing}>
            Save
          </Button>
          {` `}
          <Button type="button" variant="outlined" size="small" onClick={cancelOperatorEditing}>
            Cancel
          </Button>
        </S.TableBodyCell>
      </tr>
    );
  }

  return (
    <tr>
      <S.TableBodyCell>{operatorDbId}</S.TableBodyCell>
      <S.TableBodyCell>{firstName || '-'}</S.TableBodyCell>
      <S.TableBodyCell>{lastName || '-'}</S.TableBodyCell>
      <S.TableBodyCell width={60}>{isReadyForExplicit ? 'yes' : 'no'}</S.TableBodyCell>
      <S.TableBodyCell>{scheduleType || '-'}</S.TableBodyCell>
      <S.TableBodyCell>{selectedAgency?.name || '-'}</S.TableBodyCell>
      <S.TableBodyCell>{selectedAgency?.techName || '-'}</S.TableBodyCell>
      <S.TableBodyCell>
        <Button type="button" variant="outlined" size="small" onClick={startOperatorEditing}>
          Edit
        </Button>
      </S.TableBodyCell>
    </tr>
  );
};
