import React, { useState } from 'react';
import { Typography } from '@material-ui/core';

import { Layout } from 'src/components/App/views/Layout';

import { ModeSelect } from './components/mode-select';
import { ManagingForm } from './components/managing-form';
import { FormType, FormAction } from './types';
import { Container, ContentBlock } from './styled';

/**
 * This screen is used for the Once product only
 */
export const OperatorsAndAgenciesManagingScreen = () => {
  const [formType, setFormType] = useState<FormType>(FormType.Operator);
  const [formAction, setFormAction] = useState<FormAction>(FormAction.Create);

  return (
    <Layout>
      <Typography component="h1" variant="h5">
        Operators And Agencies Managing
      </Typography>
      <Container>
        <ContentBlock>
          <ModeSelect
            currentType={formType}
            onTypeChange={setFormType}
            currentAction={formAction}
            onActionChange={setFormAction}
          />
        </ContentBlock>
        <ContentBlock wide>
          <ManagingForm type={formType} action={formAction} />
        </ContentBlock>
      </Container>
    </Layout>
  );
};
