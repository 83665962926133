import { operatorCities, operatorLocations } from 'src/services/operator/config';
import { OperatorCities, OperatorLanguage } from 'src/types/operator';

export const onceOperatorCities = operatorCities.filter((city) => operatorLocations[city].allowedForUdates);

export const languageToCityMap = onceOperatorCities.reduce((result, city) => {
  const { language } = operatorLocations[city];
  result[language] = city;
  return result;
}, {} as Record<OperatorLanguage, OperatorCities>);

export const operatorLanguages = Object.keys(languageToCityMap) as OperatorLanguage[];
