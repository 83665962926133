import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { sessionDataStorage } from 'src/utils/session-storage';
import { useHistory } from 'react-router-dom';

import { Layout } from 'src/components/App/views/Layout';
import { ActionType, ModerationAction, UserForModeration, UserForModerationStatus } from 'src/types/moderation';
import { Gender, MediaState, MediaTag, MediaType } from 'src/types/user';
import { moderationRequest, userRequest } from 'src/network';
import { useModerationContentCount, useUserForModeration } from 'src/services/moderation/hooks';
import { ModerationContentCountResponse } from 'src/network/moderation/types';
import { useAuth } from 'src/services/auth';
import { Product } from 'src/types/product';
import { Logger, LoggerMessages, LoggerServices } from 'src/infrastructure/loggers/datadog';
import { Resources } from 'src/resources';
import { BlockUserModal } from 'src/components/Moderation/views/block-user-modal';
import { ActionButton } from 'src/components/Moderation/views/action-button';
import { ModerationHeader } from 'src/components/Moderation/views/moderation-header';
import { ImageWithLogger } from 'src/components/Moderation/views/image-with-logger';
import { VideoWithLogger } from 'src/components/Moderation/views/video-with-logger';
import { FullSizeMedia } from 'src/components/Moderation/views/full-size-media';
import { userCachedPhotoSize } from 'src/components/Moderation/utils/image-source';
import { ActionsLogger } from 'src/components/Moderation/utils/actions-logger';
import { useModerationContent } from 'src/components/Moderation/hooks/once';
import { UserRoles } from 'src/types/user/roles';

import { OnceModerationContent, OnceModerationContentLabel } from './once/components';
import { Actions } from './Actions';
import { useStyles } from './styles';

const { defaultName, defaultAbout, defaultOccupation } = Resources.strings.moderation;
const moderatedUsersCountKey = 'moderated-users-count';
const MaxOnScreenPresenceTime = 5 * 60 * 1000; // 5 minutes

export const UserModerationScreen = () => {
  const { me } = useAuth();
  const classes = useStyles({ realm: me?.realm });
  const { userForModerationState, fetchUserForModeration } = useUserForModeration();
  const [moderationActions, setModerationActions] = useState<ModerationAction[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isFullSizeOpen, setIsFullSizeOpen] = useState<boolean>(false);
  const [fullSizeMedia, setFullSizeMedia] = useState<string>('');
  const [fullSizeMediaType, setFullSizeMediaType] = useState<MediaType>(MediaType.Photo);
  const [mediaState, setMediaState] = useState<MediaState>(MediaState.Public);
  const [userGender, setUserGender] = useState<Gender | undefined>();
  const [mainUserMediaSource, setUserMainMediaSource] = useState<string | null>(null);
  const [mainUserMediaSourceFullSize, setUserMainMediaSourceFullSize] = useState<string | null>(null);
  const [mainUserMediaType, setUserMainMediaType] = useState<MediaType>(MediaType.Photo);
  const [isSending, setIsSending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const operatorId = useMemo(() => me?.id, [me]);
  const isAdministrator = useMemo(() => (me?.roles || []).includes(UserRoles.Supervisor), [me?.roles]);
  const [moderatedUsersCount, setModeratedUsersCount] = useState<number>(
    Number(sessionDataStorage.get(`${moderatedUsersCountKey}_${operatorId}`)),
  );
  const stopWordRef = useRef<HTMLDivElement>(null);
  const delayAfterUndoActionEnabledRef = useRef(false);
  const startTime = useRef(Date.now());
  const realm = me?.realm;
  const isOnceProduct = realm === Product.Once;
  const showUserAge = isOnceProduct;
  const { contentCountState, fetchContentCount } = useModerationContentCount(isOnceProduct);

  const {
    userId,
    contentId,
    newUserName,
    newUserAbout,
    newUserOccupation,
    newMediaBaseName,
    newMediaSource,
    newMediaType,
    name,
    about,
    occupation,
    gender,
    thumbnailMedia,
    mainMediaSource,
    userMedia,
    age,
    photoData: filesData,
    status: userForModerationStatus,
  } = useMemo(() => userForModerationState?.value || ({} as UserForModeration), [userForModerationState?.value]);
  const newMediaSourceFullSize = newMediaSource?.replace(userCachedPhotoSize, '');

  const { contentDecision, fetchModerationContentDecisions } = useModerationContent(userId);

  const { count: usersInQueue = 0 } = useMemo(
    () => contentCountState?.value || ({} as ModerationContentCountResponse),
    [contentCountState?.value],
  );

  const hideMainMedia = useMemo(() => {
    if (realm !== Product.Once && !mainMediaSource) {
      return true;
    }

    return (
      !!newMediaBaseName &&
      (newMediaBaseName === thumbnailMedia?.baseName || newMediaBaseName.startsWith(thumbnailMedia?.baseName))
    );
  }, [mainMediaSource, newMediaBaseName, thumbnailMedia, realm]);

  const isLoading = useMemo(() => isSending || userForModerationState?.loading, [userForModerationState, isSending]);
  const isPageFirstLoading = useMemo(
    () => userForModerationState?.loading && userForModerationState?.value === undefined,
    [userForModerationState],
  );
  const isNoUndoUsers = useMemo(() => userForModerationStatus === UserForModerationStatus.NoUndoUsers, [
    userForModerationStatus,
  ]);

  const loadData = useCallback(
    (isUndoAction?: boolean) => {
      fetchUserForModeration(isUndoAction, me?.realm).then(async (userForModeration) => {
        startTime.current = Date.now();

        const {
          userId: id,
          mainMediaSource: mainSource = '',
          mainMediaType: mainType = MediaType.Photo,
          photoData,
          newMediaBaseName: newUserMediaBaseName,
        } = userForModeration || {};

        if (id && isOnceProduct) {
          setUserMainMediaSource(mainSource);
          setUserMainMediaSourceFullSize(mainSource.replace(userCachedPhotoSize, ''));
          setUserMainMediaType(mainType);
          if (photoData && newUserMediaBaseName) {
            const fileData = photoData.files.find((file) => newUserMediaBaseName?.startsWith(file.basename));

            if (fileData?.tags.some((value) => value === 'hidden')) {
              setMediaState(MediaState.Private);
            } else {
              setMediaState(MediaState.Public);
            }
          } else {
            setMediaState(MediaState.Public);
          }
        }
      });
    },
    [fetchUserForModeration, isOnceProduct, me?.realm],
  );

  const photosCount = filesData?.files.length;

  const hasThumbnail = useMemo(() => {
    const index = filesData?.files.findIndex((file) => {
      return file.tags.includes(MediaTag.Thumbnail);
    });
    return index !== -1;
  }, [filesData]);

  const isFirstPhoto = useMemo(() => {
    return isOnceProduct
      ? !!(!hasThumbnail && newMediaSource) || !!(photosCount === 1 && newMediaSource)
      : !!(photosCount === 1 && newMediaSource);
  }, [isOnceProduct, hasThumbnail, newMediaSource, photosCount]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (contentId && userId) {
      history.push(`/moderation/content?id=${userId}`);
    }
  }, [contentId, history, userId]);

  useEffect(() => {
    if (contentId && gender) {
      setUserGender(gender);
    }
  }, [gender, contentId]);

  useEffect(() => {
    if (userForModerationState?.value === null) {
      history.push(`/moderation/content`);
    }
  }, [userForModerationState, history]);

  useEffect(() => {
    fetchContentCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchModerationContentDecisions();
  }, [fetchModerationContentDecisions]);

  const updateModeratedUsersCount = useCallback(
    (isRevert = false) => {
      setModeratedUsersCount((prevModeratedUsersCount: number) => {
        const newModeratedUsersCount = Number(prevModeratedUsersCount) + (isRevert ? -1 : 1);
        sessionDataStorage.set(`${moderatedUsersCountKey}_${operatorId}`, newModeratedUsersCount);
        return newModeratedUsersCount;
      });
    },
    [operatorId],
  );

  const updateUserThumbnail = useCallback(() => {
    const newThumbnailMedia = userMedia?.find((mediaItem) => mediaItem.baseName !== thumbnailMedia?.baseName);

    if (newThumbnailMedia) {
      const { baseName: newBaseName, mediaType } = newThumbnailMedia;
      const setTagMethod = mediaType.startsWith('video') ? userRequest.setUserVideoTag : userRequest.setUserPhotoTag;

      setTagMethod(userId, newBaseName, MediaTag.Thumbnail).catch((error) => {
        Logger.log({
          service: LoggerServices.Moderation,
          message: LoggerMessages.UpdateUserThumbnailError,
          product: me?.realm || '',
          payload: {
            mediaType,
            userId,
            newBaseName,
            operatorId,
            moderatedUsersCount,
            error: error?.response,
          },
        });
      });
    }
  }, [thumbnailMedia?.baseName, me?.realm, operatorId, userId, userMedia, moderatedUsersCount]);

  const updatePageWithNewUser = useCallback(
    (isUndoAction = false) => {
      setModerationActions([]);
      setUserGender(undefined);
      setUserMainMediaSource(null);

      fetchContentCount();
      loadData(isUndoAction);

      if (isOnceProduct && isUndoAction) {
        delayAfterUndoActionEnabledRef.current = true;
      }
    },
    [isOnceProduct, fetchContentCount, loadData],
  );

  const sendModerationActions = useCallback(
    async (actions: ModerationAction[]) => {
      const presenceOnScreen = Date.now() - startTime.current;

      if (presenceOnScreen >= MaxOnScreenPresenceTime) {
        enqueueSnackbar('The wait was too long, please reload your screen', { variant: 'error' });
        return;
      }

      if (operatorId) {
        if (!isNoUndoUsers && actions.length > 0) {
          const sendModerationActionsSuccessFlow = () => {
            updateModeratedUsersCount();

            const needToUpdateUserThumbnail = actions.some(
              (action) => action.actionValue === mainMediaSource.replace('.swipe', ''),
            );

            if (needToUpdateUserThumbnail) {
              updateUserThumbnail();
            }

            const noNeedToUpdatePage =
              isOnceProduct && actions.length === 1 && actions[0].actionType === ActionType.Reject;

            if (!noNeedToUpdatePage) {
              updatePageWithNewUser();
            }
          };

          const handleSendActions = () => {
            setIsSending(true);

            moderationRequest
              .sendModerationActions(operatorId, contentId, actions)
              .then((response) => {
                ActionsLogger.log(LoggerMessages.SendUserModerationActions, {
                  actions,
                  product: me?.realm || '',
                  operatorId,
                  contentId,
                  userId,
                  response: response as any,
                });

                if (!isOnceProduct || delayAfterUndoActionEnabledRef.current) {
                  sendModerationActionsSuccessFlow();
                  delayAfterUndoActionEnabledRef.current = false;
                }
                setIsSending(false);
              })
              .catch((error) => {
                setIsSending(false);

                if (isOnceProduct) {
                  enqueueSnackbar(
                    `Send moderation action:${error?.response ? String(error?.response) : String(error)}`,
                    { variant: 'error' },
                  );
                  updateModeratedUsersCount(true);
                }

                Logger.log({
                  service: LoggerServices.Moderation,
                  message: LoggerMessages.SendModerationActionsError,
                  product: me?.realm || '',
                  payload: {
                    contentId,
                    operatorId,
                    moderatedUsersCount,
                    error: error?.response,
                  },
                });
              });

            if (isOnceProduct && !delayAfterUndoActionEnabledRef.current) {
              sendModerationActionsSuccessFlow();
              setIsSending(false);
            }
          };

          handleSendActions();
        } else {
          updatePageWithNewUser();
        }
      } else {
        enqueueSnackbar('Cannot get your user id. Try to reload or re-signin', { variant: 'error' });
      }
    },
    [
      operatorId,
      enqueueSnackbar,
      isNoUndoUsers,
      updateModeratedUsersCount,
      mainMediaSource,
      updateUserThumbnail,
      updatePageWithNewUser,
      contentId,
      isOnceProduct,
      me?.realm,
      userId,
      moderatedUsersCount,
    ],
  );

  const addModerationAction = useCallback(
    (actionType: ActionType, actionValue?: string) => {
      setModerationActions([
        ...moderationActions.filter((action) => action.actionType !== actionType),
        {
          actionType,
          ...(actionValue !== undefined && { actionValue }),
        },
      ]);
    },
    [moderationActions],
  );

  const isPressed = useCallback(
    (actionType: ActionType, actionValue?: string) => {
      return (
        moderationActions.some((action) =>
          actionValue
            ? action.actionType === actionType && action.actionValue === actionValue
            : action.actionType === actionType,
        ) ||
        (actionType === ActionType.SetGender && userGender === actionValue) ||
        (actionType === ActionType.SetPrivatePhoto && mediaState === actionValue) ||
        (actionType === ActionType.SetPublicPhoto && mediaState === actionValue)
      );
    },
    [mediaState, moderationActions, userGender],
  );

  const toggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const toggleFullSizeModal = useCallback(
    ({ mediaSource, mediaType }) => {
      setFullSizeMedia(mediaSource);
      setFullSizeMediaType(mediaType);
      setIsFullSizeOpen(!isFullSizeOpen);
    },
    [isFullSizeOpen],
  );

  const resetName = useCallback(() => {
    addModerationAction(ActionType.ResetName, defaultName);
  }, [addModerationAction]);

  const resetAbout = useCallback(() => {
    addModerationAction(ActionType.ResetAbout, defaultAbout);
  }, [addModerationAction]);

  const resetOccupation = useCallback(() => {
    addModerationAction(ActionType.ResetOccupation, defaultOccupation);
  }, [addModerationAction]);

  const blockProfile = useCallback(() => {
    toggleModal();
    sendModerationActions([...moderationActions, { actionType: ActionType.Delete }]);
  }, [sendModerationActions, toggleModal, moderationActions]);

  const undoAction = useCallback(
    (actionType: ActionType) => () => {
      setModerationActions([...moderationActions.filter((action) => action.actionType !== actionType)]);
    },
    [moderationActions],
  );

  const mediaBorder = isOnceProduct ? classes.mediaBorderRed : classes.mediaBorderBlue;
  const mediaClass = isOnceProduct ? classes.uDatesMedia : classes.media;
  const fieldChanged = isOnceProduct ? classes.fieldChangedRed : classes.fieldChangedBlue;

  const renderMedia = useCallback(
    (source: string, mediaType: MediaType, showBorder?: boolean, basename?: string) => {
      return mediaType === MediaType.Video ? (
        <VideoWithLogger
          className={clsx(mediaClass, showBorder && mediaBorder)}
          src={source}
          basename={basename}
          autoPlay
          loop
        />
      ) : (
        <ImageWithLogger
          className={clsx(mediaClass, showBorder && mediaBorder)}
          src={source}
          basename={basename}
          alt="mainPhoto"
        />
      );
    },
    [mediaClass, mediaBorder],
  );

  const renderAboutField = useMemo(() => (isPressed(ActionType.ResetAbout) ? defaultAbout : newUserAbout || about), [
    about,
    isPressed,
    newUserAbout,
  ]);

  const aboutActionButtonProps = useMemo(() => {
    const actionType = ActionType.ResetAbout;
    if (isPressed(actionType)) {
      return {
        onClick: undoAction(actionType),
        title: isOnceProduct
          ? Resources.strings.moderation.undoResetBioTitleUdates
          : Resources.strings.moderation.undoResetBioTitle,
      };
    }

    return {
      onClick: resetAbout,
      title: isOnceProduct
        ? Resources.strings.moderation.resetBioTitleUdates
        : Resources.strings.moderation.resetBioTitle,
    };
  }, [isPressed, undoAction, resetAbout, isOnceProduct]);

  const nameTitles = useMemo(() => {
    const endingPart = showUserAge && age > 0 ? `, ${age}` : '';

    return {
      defaultName: `${defaultName}${endingPart}`,
      newUserName: `${newUserName || name}${endingPart}`,
    };
  }, [age, name, newUserName, showUserAge]);

  if (isPageFirstLoading) {
    return (
      <Layout containerSize="lg">
        <ModerationHeader usersInQueue={usersInQueue} moderatedUsersCount={moderatedUsersCount} />
        <Box className={classes.placeholderContainer}>
          <CircularProgress />
        </Box>
      </Layout>
    );
  }

  if (userForModerationState?.value === null) {
    return (
      <Layout containerSize="lg">
        <ModerationHeader usersInQueue={usersInQueue} moderatedUsersCount={moderatedUsersCount} />
        <Box className={classes.placeholderContainer}>
          <Typography className={classes.placeholderText}>No content for moderation available.</Typography>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout containerSize="lg">
      <BlockUserModal isOpen={isModalOpen} onBlock={blockProfile} onCancel={toggleModal} />
      {(newMediaSource && newMediaType) || (mainUserMediaSource && mainUserMediaType) ? (
        <FullSizeMedia
          isOpen={isFullSizeOpen}
          setIsOpen={setIsFullSizeOpen}
          source={fullSizeMedia}
          mediaType={fullSizeMediaType}
          basename={newMediaBaseName}
        />
      ) : (
        <></>
      )}
      <Box className={classes.mainContainer}>
        <ModerationHeader usersInQueue={usersInQueue} moderatedUsersCount={moderatedUsersCount} />

        {isLoading && (
          <Box className={classes.placeholderContainer}>
            <CircularProgress />
          </Box>
        )}

        {isNoUndoUsers && !isLoading && (
          <Box className={classes.placeholderContainer}>
            <Typography className={classes.placeholderText}>You can’t undo any more</Typography>
          </Box>
        )}

        {!isNoUndoUsers && !isLoading && (
          <Box className={classes.container}>
            <Box className={classes.leftContainer}>
              {isOnceProduct && (
                <OnceModerationContentLabel
                  contentDecision={contentDecision}
                  scrollRef={stopWordRef}
                  containerClassname={classes.moderationContentContainer}
                />
              )}
              <Box className={classes.nameWithPhotoContainer}>
                <Box className={clsx(classes.fieldContainer, newUserName && fieldChanged)}>
                  <Typography className={classes.name}>
                    {isPressed(ActionType.ResetName) ? nameTitles.defaultName : nameTitles.newUserName}
                  </Typography>
                  <ActionButton
                    {...(isPressed(ActionType.ResetName)
                      ? {
                          onClick: undoAction(ActionType.ResetName),
                          title: Resources.strings.moderation.undoResetNameTitle,
                        }
                      : {
                          onClick: resetName,
                          title: Resources.strings.moderation.resetNameTitle,
                        })}
                    disabled={isLoading}
                  />
                </Box>
              </Box>
              <Box className={clsx(classes.fieldContainer, newUserAbout && fieldChanged)}>
                <Typography className={classes.bio}>
                  {renderAboutField}
                </Typography>
                <ActionButton {...aboutActionButtonProps} disabled={isLoading} />
              </Box>
              {isOnceProduct && (
                <Box className={clsx(classes.fieldContainer, newUserOccupation && fieldChanged)}>
                  <Typography className={classes.occupation}>
                    {isPressed(ActionType.ResetOccupation) ? defaultOccupation : newUserOccupation || occupation}
                  </Typography>
                  <ActionButton
                    {...(isPressed(ActionType.ResetOccupation)
                      ? {
                          onClick: undoAction(ActionType.ResetOccupation),
                          title: 'Undo reset work (W)',
                        }
                      : {
                          onClick: resetOccupation,
                          title: 'Reset work (W)',
                        })}
                    disabled={isLoading}
                  />
                </Box>
              )}
              <Box className={classes.fieldContainer}>
                <ActionButton
                  color="error"
                  onClick={toggleModal}
                  title={Resources.strings.moderation.blockProfile}
                  disabled={isLoading}
                />
              </Box>
            </Box>
            <Box className={classes.rightContainer}>
              <Box className={classes.mediasContainer}>
                {!hideMainMedia && (
                  <Box className={classes.mediaContainer}>
                    <Typography className={classes.mediaName}>{Resources.strings.moderation.media.main}</Typography>
                    {mainUserMediaSource || isFirstPhoto ? (
                      <Box
                        className={clsx(
                          classes.cursorPointer,
                          isPressed(ActionType.Reject) && isFirstPhoto ? classes.rejectPressed : '',
                        )}
                        onClick={() => {
                          toggleFullSizeModal(
                            isFirstPhoto && newMediaSource && newMediaType
                              ? { mediaSource: newMediaSourceFullSize, mediaType: newMediaType }
                              : {
                                  mediaSource: mainUserMediaSourceFullSize,
                                  mediaType: mainUserMediaType,
                                },
                          );
                        }}
                      >
                        {isFirstPhoto && newMediaSource && newMediaType
                          ? renderMedia(newMediaSource, newMediaType, true)
                          : mainUserMediaSource && renderMedia(mainUserMediaSource, mainUserMediaType)}
                      </Box>
                    ) : (
                      <Box className={mediaClass}>
                        <CircularProgress />
                      </Box>
                    )}
                  </Box>
                )}
                {newMediaSource && newMediaType && !isFirstPhoto && (
                  <Box
                    className={classes.mediaContainer}
                    onClick={() => {
                      toggleFullSizeModal({ mediaSource: newMediaSourceFullSize, mediaType: newMediaType });
                    }}
                  >
                    <Typography className={classes.mediaName}>{Resources.strings.moderation.media.new}</Typography>
                    <Box className={isPressed(ActionType.Reject) ? classes.rejectPressed : ''}>
                      {renderMedia(newMediaSource, newMediaType, true, newMediaBaseName)}
                    </Box>
                  </Box>
                )}
              </Box>
              {isOnceProduct && (
                <Box className={classes.userIdContainer}>
                  <Typography>ID {userId}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        )}
        <Actions
          undoAction={undoAction}
          resetAbout={resetAbout}
          resetName={resetName}
          resetOccupation={resetOccupation}
          moderationActions={moderationActions}
          addModerationAction={addModerationAction}
          isPressed={isPressed}
          setUserGender={setUserGender}
          setIsSending={setIsSending}
          newMediaSource={newMediaSource}
          mainMediaSource={mainMediaSource}
          isLoading={isLoading}
          sendModerationActions={sendModerationActions}
          setMediaState={setMediaState}
          updatePageWithNewUser={updatePageWithNewUser}
          isNoUndoUsers={isNoUndoUsers}
          newMediaType={newMediaType}
        />
        {isOnceProduct && (
          <OnceModerationContent
            contentDecision={contentDecision}
            ref={stopWordRef}
            isAdministrator={isAdministrator}
            userId={userId}
          />
        )}
      </Box>
    </Layout>
  );
};
