import { makeStyles, Theme } from '@material-ui/core';

export const textLineHeight = 24;
export const textMaxLines = 8;

export const useStyles = makeStyles<Theme>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '191.5px',
    height: '256px',
    padding: '16px',
    borderRadius: '12px',
    border: '1px solid #EDEDED',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
    position: 'relative',
    cursor: 'pointer',
  },

  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: textMaxLines,
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    textAlign: 'start',
    fontSize: '16px',
    lineHeight: `${textLineHeight}px`,
  },

  overflowingContainer: {
    display: 'flex',
    width: '24px',
    height: '24px',
    padding: '7.5px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '7.5px',
    position: 'absolute',
    right: '16px',
    bottom: '16px',
    borderRadius: '8px',
    background: 'rgba(0, 0, 0, 0.50)',
  },
});
