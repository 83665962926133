import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useChangeOperatorCommission, useOperators } from 'src/services/operator/hooks';
import { validCommissions } from 'src/services/operator/config';

import { SubmitButton } from '../components/submit-button';
import { Select } from '../components/select';
import { AutocompleteInput } from '../components/autocomplete-input';
import { Form } from '../components/styled';

import { validationSchema, defaultValues } from './schema';

export const ChangeOperatorCommissionForm = () => {
  const changeOperatorCommission = useChangeOperatorCommission();
  const { operators, loading, fetchOperators } = useOperators();

  const { control, handleSubmit, errors, formState, reset, watch, setError, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleFormSubmit = handleSubmit(async (values) => {
    const success = await changeOperatorCommission(values);
    if (success) {
      reset();
      fetchOperators();
    }
  });

  const operatorIds = useMemo(() => operators.map((op) => op.operatorId), [operators]);

  const operatorId = watch('operatorId');
  const selectedOperator = useMemo(() => operators.find((operator) => operator.operatorId === operatorId), [
    operators,
    operatorId,
  ]);

  useEffect(() => {
    if (loading || !operatorId) {
      return;
    }

    if (!selectedOperator) {
      setError('operatorId', { message: `Operator with id: ${operatorId} not found` });
    } else if (validCommissions.includes(selectedOperator.commission)) {
      setValue('commission', selectedOperator.commission);
    }
  }, [loading, operatorId, selectedOperator, setError, setValue]);

  return (
    <Form onSubmit={handleFormSubmit}>
      <AutocompleteInput
        name="operatorId"
        label="Operator id"
        control={control}
        errors={errors}
        loading={loading}
        options={operatorIds}
      />
      {selectedOperator && (
        <Select
          name="commission"
          control={control}
          errors={errors}
          label="Operator commission"
          items={validCommissions}
        />
      )}
      <SubmitButton title="Change Operator Commission" submitting={formState.isSubmitting} />
    </Form>
  );
};
