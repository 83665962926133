import { Http } from 'src/network/http';
import { AnnalsEvents } from 'src/types/annals';

const logEvent = (userId: string, eventName: AnnalsEvents, body: Record<string, unknown>) => {
  const bodyWithExtraData = {
    ...body,
    currentUserId: userId,
    timestamp: Date.now(),
  };

  return Http.shared().instance.post(`/annals/${userId}/${eventName}`, bodyWithExtraData, {
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
    },
  });
};

const logRiskManagmentEvents = (userId: string, body: Record<string, unknown>) => {
  return Http.shared().instance.post(`/annals/${userId}/risk-managment`, body, {
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
    },
  });
};

export const annalsRequest = {
  logRiskManagmentEvents,
  logEvent,
};
