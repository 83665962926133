export namespace ONCE {
  export enum ContentDecisionType {
    Text = 'text',
    Photo = 'photo',
    Message = 'message',
    Thumbnail = 'thumbnail',
  }

  export enum ContentDecisionSource {
    About = 'about',
    Name = 'name',
    Occupation = 'occupation',
  }

  export type ContentDecisionCategoryLevel = {
    category: string;
    level: string;
  };

  export type ContentDecision = {
    categories?: string[];
    categoryLevels?: ContentDecisionCategoryLevel[];
    content: string;
    contentId: number;
    contentSource?: ContentDecisionSource;
    contentType: ContentDecisionType;
    decision: string;
    initiatedBy: string;
    timestamp: string;
  };

  export type ModerationContentDecisions = {
    decisions: ContentDecision[];
  };
}
