import { useCallback, useState } from 'react';
import { userRequest } from 'src/network';
import { useActionsContext, useDataContext } from './useContextHooks';
import { useActionErrorHandler } from './useActionErrorHandler';
import { useModerationDetails } from './useModerationDetails';
import { AnnalsLogEvent, useAnnalsEventLog } from './useAnnalsEventLog';

export const useCancelPremiumAction = () => {
  const { userId, operatorRoles } = useModerationDetails();
  const [isLoading, setIsLoading] = useState(false);
  const { actualSubscription } = useDataContext();
  const { updateActualSubscription } = useActionsContext();
  const unsubscribed = !!actualSubscription?.cancelDate;

  const errorHandler = useActionErrorHandler();
  const logEvent = useAnnalsEventLog();

  const unsubscribe = useCallback(() => {
    if (!actualSubscription || unsubscribed) {
      return;
    }

    const event: AnnalsLogEvent = {
      action: 'canceled-subscription',
      sku: actualSubscription.sku,
    };

    setIsLoading(true);
    userRequest
      .cancelSubscription(userId, actualSubscription.sku, actualSubscription.externalSystem, 'risk-moderation')
      .then(updateActualSubscription)
      .then(() => {
        logEvent(event);
      })
      .catch((error) =>
        errorHandler('Cancel Premium', {
          error,
        }),
      )
      .finally(() => setIsLoading(false));
  }, [actualSubscription, errorHandler, logEvent, unsubscribed, updateActualSubscription, userId]);

  return {
    available: operatorRoles.isFinanceOrAdmin && actualSubscription?.externalSystem === 'Moto',
    isLoading,
    unsubscribed,
    unsubscribe: !unsubscribed ? unsubscribe : undefined,
  };
};
