import { AxiosResponse } from 'axios';
import { Http } from 'src/network/http';

export type ApiMessage = {
  id: number;
  timestamp: string;
  senderId: number; // back-end format
  recipientId: number; // back-end format
  text: string;
};

type ApiGetMessages = {
  messages: ApiMessage[];
};

const getSuspiciousMessages = (userId: string) =>
  Http.shared()
    .instance.get<ApiGetMessages>(`dialogs/messages/${userId}/suspicious`)
    .then((response: AxiosResponse<ApiGetMessages>) => response.data);

export const dialogsRequest = {
  getSuspiciousMessages,
};
