import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  gap: 20px;
`;

export const ContentBlock = styled.div<{ wide?: boolean }>`
  flex: 1;
  max-width: ${(props) => (props.wide ? '365px' : '337px')};
`;
