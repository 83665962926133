import React from 'react';

import { CheckBox } from './components';
import { Option } from '../bubble-checkboxes-list';
import { useStyles } from './styles';

type Props = {
  options: Option[];
  selected: string[];
  setSelected?: React.Dispatch<React.SetStateAction<string[]>>;
  disabled?: boolean;
  isMultiple?: boolean;
};

export const CheckBoxesList = React.memo((props: Props) => {
  const { options, selected, setSelected, disabled, isMultiple = true } = props;
  const classes = useStyles();

  const toggleOption = (value: string) => () => {
    if (setSelected) {
      if (isMultiple) {
        if (selected.includes(value)) {
          setSelected(selected.filter((it) => it !== value));
        } else {
          setSelected([...selected, value]);
        }
      } else {
        setSelected([value]);
      }
    }
  };

  return (
    <div className={classes.container}>
      {options.map((it) => (
        <CheckBox
          key={it.value}
          text={it.text}
          isActive={selected.includes(it.value)}
          onClick={toggleOption(it.value)}
          disabled={disabled}
        />
      ))}
    </div>
  );
});
