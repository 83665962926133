import React from 'react';

import { LabelText } from 'src/components/common/flure';

import { FilterButton } from '../filter-button';
import { useStyles } from './styles';

type Props = {
  appliedFilters: string[];
  setAppliedFilters: React.Dispatch<React.SetStateAction<string[]>>;
};

export const ScreenHeader = React.memo(({ appliedFilters, setAppliedFilters }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <LabelText text="Verification Photos History" />
      <FilterButton appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} />
    </div>
  );
});
