import { Http } from 'src/network/http';
import { ONCE } from 'src/types/once';

import { adapter } from '../adapter';

import { OnceModerationGroup } from './types';

const getModerationContentDecisions = (userId: string, decision: string) => {
  return Http.shared()
    .instance.get<ONCE.ModerationContentDecisions>(`/moderation/intelligence/${userId}/content/decisions/${decision}`)
    .then(adapter.getResponseData);
};

const setOnceModerationGroup = (userId: string, group: OnceModerationGroup) => {
  return Http.shared()
    .instance.post(`/once/moderation/${userId}/group`, {
      group,
    })
    .then(adapter.getModerationContent);
};

export const onceModerationRequest = {
  getModerationContentDecisions,
  setOnceModerationGroup,
};
