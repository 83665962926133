export type DictType = {
  [key: string]: string;
};

export const interestsDict: DictType = {
  vegan: 'Vegan',
  smoking: 'Smoking',
  'casual-drinking': 'Casual Drinking',
  'friendly-420': '420 Friendly',
  'fast-food': 'Fast Food',
  'health-conscious': 'Health-conscious',
  'eco-conscious': 'Eco-conscious',
  religious: 'Religious',
  'early-riser': 'Early Riser',
  'night-owl': 'Night Owl',
  traveler: 'Traveler',
  'party-spirit': 'Party Spirit',
  homebody: 'Homebody',
  gaming: 'Gaming',
  fitness: 'Fitness',
  music: 'Music',
  cooking: 'Cooking',
  nature: 'Nature',
  extroverted: 'Extroverted',
  introverted: 'Introverted',
  'small-talk': 'Small Talk',
  'deep-conversations': 'Deep Conversations',
  sports: 'Sports',
  reading: 'Reading',
  art: 'Art',
  crafting: 'Crafting',
  dance: 'Dance',
  writing: 'Writing',
  knitting: 'Knitting',
  travel: 'Travel',
  running: 'Running',
  'outdoor-activity': 'Outdoor activity',
  movies: 'Movies',
  hiking: 'Hiking',
  photography: 'Photography',
  shopping: 'Shopping',
  food: 'Food',
};

export const getDictionaryValues = (ids: string[], dictionary: DictType) => {
  return ids ? ids.map((it) => dictionary[it]).filter((it) => !!it) : [];
};
