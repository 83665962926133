import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },

  defaultCursor: {
    cursor: 'default',
  },

  icon: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
  },

  text: {
    height: '24px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    msTextOverflow: 'ellipsis',
  }
});
