import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  contentContainer: {
    height: window.innerHeight - 170,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },

  verificationPhotos: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    columnGap: '6px',
    rowGap: '6px',
  },
});
