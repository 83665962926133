import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '426px',
    height: 'calc(100vh - 150px)',
    margin: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  title: {
    color: '#a8a8a8',
    fontSize: '32px',
    marginBottom: '28px',
    fontWeight: 400,
    whiteSpace: 'nowrap',
  },

  formControl: {
    width: '100%',
  },

  textInput: {
    width: '100%',
    height: '64px',
    color: 'black',
    textAlign: 'center',
    paddingRight: 0,
    borderRadius: '8px',

    '& fieldset': {
      borderColor: '#985EF8 !important',

      '&:focus': {
        borderColor: '#985EF8 !important',
      },

      '&:hover': {
        borderColor: '#985EF8 !important',
      },

      '&:active': {
        borderColor: '#985EF8 !important',
      },
    },
  },

  findButton: {
    backgroundColor: '#985EF8',
    color: 'white',
    fontSize: '16px',
    textTransform: 'none',
    height: '64px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    width: '96px',
    '&:hover': {
      backgroundColor: '#985EF8',
      opacity: 0.8,
    },
  },
});
