import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';
import { Button, MenuItem } from '@material-ui/core';
import { useAuth } from 'src/services/auth';
import { Reports } from 'src/types/report/common';
import { getAppPages, getProductsMap, getUserRolesMap } from 'src/components/config';

import { MenuButton } from '../menu-button';

export const UDatesMenu = () => {
  const { me } = useAuth();

  const rolesMap = useMemo(() => getUserRolesMap(me?.roles || []), [me?.roles]);
  const productsMap = useMemo(() => getProductsMap(me?.realm), [me?.realm]);
  const { RiskModeration } = useMemo(() => getAppPages(productsMap, rolesMap), [productsMap, rolesMap]);

  const { isAgencyAdministrator, isAdministrator, isOperator, isCustomerSupport } = rolesMap;
  const renderModerationSection = isOperator || isAdministrator || isCustomerSupport;

  return (
    <>
      <MenuButton keyId="menu-reports" type="text" title="Reports">
        <MenuItem to={`/report-by-${Reports.commissions}`} component={Link}>
          Report by commissions
        </MenuItem>
        <MenuItem to={`/report-by-${Reports.messages}`} component={Link}>
          Report by messages
        </MenuItem>
        <MenuItem to={`/report-by-${Reports.activity}`} component={Link}>
          Report by activity
        </MenuItem>
        <MenuItem to={`/report-by-${Reports.kpi}`} component={Link}>
          Report by KPI
        </MenuItem>
      </MenuButton>
      {isAgencyAdministrator && (
        <MenuButton keyId="menu-multichat" type="text" title="Multichat">
          <MenuItem to="/multichat/life-stories" component={Link}>
            Life-stories
          </MenuItem>
          <MenuItem to="/multichat/agencies-operators-mapping" component={Link}>
            Agencies-Operators mapping
          </MenuItem>
          <MenuItem to="/multichat/animators-activator" component={Link} key="animators-activator">
            Animators activator
          </MenuItem>
          <MenuItem to="/multichat/agencies-animators-mapping" component={Link} key="agencies-animators-mapping">
            Agencies-Animators mapping
          </MenuItem>
          <MenuItem to="/multichat/operators-and-agencies-managing" component={Link}>
            Operators and agencies managing
          </MenuItem>
        </MenuButton>
      )}
      {renderModerationSection && (
        <Button to="/moderation" component={Link} color="inherit">
          Moderation
        </Button>
      )}
      {RiskModeration.isAvailable && (
        <Button to={RiskModeration.routes.Main} component={Link} color="inherit">
          Risk Managment
        </Button>
      )}
    </>
  );
};
