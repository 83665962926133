import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  selectedFilters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: '12px',
    rowGap: '4px',
    flexWrap: 'wrap',
  },

  item: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '4px',
    alignItems: 'center',

    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  deleteButton: {
    border: 'none',
    padding: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',

    '& img': {
      width: '8px',
      height: '8px',
    },
  },
});
