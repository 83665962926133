import { useCallback } from 'react';

import { agencyRequest } from 'src/network';
import { getRealm } from 'src/utils/realm';
import { useAuth } from 'src/services/auth';
import { useSnackbar } from 'src/utils/snackbar';

export type ChangeAgencyActiveStatusPayload = {
  name: string;
  techName: string;
  isActive: boolean;
};

export const useChangeAgencyActiveStatus = () => {
  const { showErrorMessage, showSuccessMessage } = useSnackbar();
  const realm = useAuth().me?.realm;

  const changeAgencyActiveStatus = useCallback(
    async (payload: ChangeAgencyActiveStatusPayload) => {
      const { techName, name, isActive } = payload;

      try {
        await agencyRequest.updateAgency(techName, {
          isActive,
          realm: getRealm(realm),
        });

        showSuccessMessage(`Agency ${name} has been ${isActive ? 'reactivated' : 'deactivated'}`);

        return true;
      } catch (error) {
        showErrorMessage((error as Error)?.message || 'Bad request');

        return false;
      }
    },
    [showErrorMessage, showSuccessMessage, realm],
  );

  return changeAgencyActiveStatus;
};
