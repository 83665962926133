import React, { useState } from 'react';
import clsx from 'clsx';

import { LabelText, TitleText } from 'src/components/common/flure';
import { useGetMediaSourceAuthedFullSize } from 'src/components/Moderation/utils/image-source';
import dislikeIcon from 'src/components/FlureFeed/assets/dislike-icon.png';
import likeIcon from 'src/components/FlureFeed/assets/like-icon.png';
import likeIconLight from 'src/components/FlureFeed/assets/like-icon-light.png';
import popularBackground from 'src/components/FlureFeed/assets/popular-background.png';
import popularBackgroundLight from 'src/components/FlureFeed/assets/popular-background-light.png';
import paginationIcon from 'src/components/FlureFeed/assets/pagination-icon.png';
import { Gender, MediaTag } from 'src/types/user';
import { formatDistance, formatUserFields } from 'src/components/FlureFeed/utils';
import { ApiFeedUser } from 'src/network/flure-feed/types';

import noPhotoFem from './no-photo-yet-fem.webp';
import noPhotoMal from './no-photo-yet-mal.webp';
import { useStyles } from './styles';

type Props = {
  user: ApiFeedUser;
  isVirtualLocation: boolean;
};

const FeedCardView = (props: Props) => {
  const { user, isVirtualLocation } = props;
  const classes = useStyles();
  const getMediaSource = useGetMediaSourceAuthedFullSize();

  const {
    userId,
    media,
    age,
    'sexual-orientation': sexualOrientation,
    hasPartner,
    gender,
    distance,
    attractiveness,
    isPopular,
  } = user || {};
  const notHiddenMedia = media.filter((it) => !it.tags.includes(MediaTag.Hidden));
  const mainMedia =
    notHiddenMedia.find((it) => it.tags.includes(MediaTag.Thumbnail)) ||
    notHiddenMedia.find((it) => !it.tags.includes(MediaTag.Spicy));
  const noPhotoSource = gender === Gender.Male ? noPhotoMal : noPhotoFem;
  const imageSource = mainMedia ? getMediaSource(userId, mainMedia.baseName, mainMedia.mediaType, false) : undefined;
  const [showNoPhoto, setShowNoPhoto] = useState(!imageSource);

  const userInfo = formatUserFields({ age, sexualOrientation, gender, hasPartner });
  const distanceString = !isVirtualLocation ? formatDistance(distance) : '';

  return (
    <div className={clsx(classes.container, isPopular && classes.largerPadding)}>
      {isPopular && (
        <img
          className={classes.popularBackground}
          src={isVirtualLocation ? popularBackgroundLight : popularBackground}
          alt="popularBackground"
        />
      )}
      <div className={classes.imageContainer}>
        <div className={classes.likesCount}>
          <LabelText text={`${attractiveness} ${attractiveness === 1 ? 'like' : 'likes'}`} />
        </div>
        <div className={classes.userId}>
          <LabelText text={userId} />
        </div>
        {imageSource && !showNoPhoto && (
          <img className={classes.userPhoto} src={imageSource} alt="userPhoto" onError={() => setShowNoPhoto(true)} />
        )}
        {showNoPhoto && (
          <div className={classes.userNoPhotoContainer}>
            <img className={classes.userNoPhoto} src={noPhotoSource} alt="userPhoto" />
          </div>
        )}
        <div className={classes.userInfo}>
          <TitleText className={classes.name} text={user.name} />
          <LabelText className={classes.infoText} text={userInfo} />
          {distanceString && <LabelText className={classes.infoText} text={distanceString} />}
        </div>
        <div className={classes.gradient} />
      </div>
      <div className={classes.bottomContainer}>
        <div className={classes.paginationContainer}>
          <img className={classes.paginationIcon} src={paginationIcon} alt="paginationIcon" />
        </div>
        <img className={classes.likeIcon} src={dislikeIcon} alt="dislikeIcon" />
        <img className={classes.likeIcon} src={isVirtualLocation ? likeIconLight : likeIcon} alt="likeIcon" />
      </div>
    </div>
  );
};

export const FeedCard = React.memo(FeedCardView);
