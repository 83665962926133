export enum Reports {
  activity = 'activity',
  commissions = 'commissions',
  messages = 'messages',
  kpi = 'kpi',
}

export type DefaultReportSearchMode = 'agency' | 'all' | 'all-without-agency' | 'operator';

export type ReportLanguage = 'all' | 'en' | 'fr' | 'es';

export type DefaultReportFormFields = {
  rangeDate: string;
  mode: DefaultReportSearchMode;
  operatorId?: number;
  agencyTechName?: string;
  language?: ReportLanguage;
};

export type DataItemBase = {
  id: string;
  operatorName: string;
  operatorId: string;
  agencyName: string;
};
