import React, { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';

import { useStyles } from '../styles';
import { useModerationUser } from '../hooks/useModerationDetails';

export const UserInfo = () => {
  const classes = useStyles();
  const { userId, name, age, about, occupation } = useModerationUser();

  const userTitle = useMemo(() => {
    return [name || 'Unknown', age].filter(Boolean).join(', ');
  }, [name, age]);

  if (!userId) {
    return null;
  }

  return (
    <Box className={classes.userProfile}>
      <Typography component="h2" className={classes.userTitle}>
        {userTitle}
      </Typography>
      {about && <Typography className={classes.userText}>{about}</Typography>}
      {occupation && <Typography className={classes.userText}>{occupation}</Typography>}
      <Typography className={classes.userId}>ID: {userId}</Typography>
    </Box>
  );
};
