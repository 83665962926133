import React, { useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'src/utils/snackbar';

import { ActionButton } from 'src/components/Moderation/views/action-button';
import { UserTagType } from 'src/types/user';
import { onceModerationRequest } from 'src/network/moderation/once';
import { OnceModerationGroup } from 'src/network/moderation/once/types';
import { userRequest } from 'src/network';
import { noop } from 'src/utils/functions';

type Props = {
  userId: string;
  isLoading: boolean;
  userTags?: UserTagType[] | undefined;
  setUserTags: (tags: UserTagType[]) => void;
};

export const OnceAdministratorActions = (props: Props) => {
  const { isLoading: isLoadingProp, userId, userTags, setUserTags } = props;

  const [isLoading, setIsLoading] = useState(false);

  const { showErrorMessage } = useSnackbar();

  const errorHandler = (action: string, payload: { error: unknown }) => {
    showErrorMessage(`Action: ${action} - Error: ${payload.error}`);
  };

  const updateUserTags = async () => {
    await userRequest.getUserTags(userId).then(setUserTags).catch(noop);
  };

  const onScam = () => {
    setIsLoading(true);
    onceModerationRequest
      .setOnceModerationGroup(userId, OnceModerationGroup.Scam)
      .then(updateUserTags)
      .catch((error) => errorHandler(OnceModerationGroup.Scam, { error }))
      .finally(() => setIsLoading(false));
  };

  const onAnim = () => {
    setIsLoading(true);
    onceModerationRequest
      .setOnceModerationGroup(userId, OnceModerationGroup.Unapproved)
      .then(updateUserTags)
      .catch((error) => errorHandler(OnceModerationGroup.Unapproved, { error }))
      .finally(() => setIsLoading(false));
  };

  const onCommon = () => {
    setIsLoading(true);
    onceModerationRequest
      .setOnceModerationGroup(userId, OnceModerationGroup.Common)
      .then(updateUserTags)
      .catch((error) => errorHandler(OnceModerationGroup.Common, { error }))
      .finally(() => setIsLoading(false));
  };

  const groupTag = useMemo(
    () => userTags?.find((tag) => tag === UserTagType.Scam || tag === UserTagType.Unapproved) || '',
    [userTags],
  );

  const isInScamGroup = groupTag === UserTagType.Scam;
  const isInAnimatorsGroup = groupTag === UserTagType.Unapproved;
  const isCommonGroup = !isInScamGroup && !isInAnimatorsGroup;

  const loading = isLoadingProp || isLoading;

  return (
    <>
      <Box ml={3}>
        <ActionButton onClick={onScam} title="Scam" disabled={isInScamGroup || loading} />
      </Box>
      <Box>
        <ActionButton onClick={onAnim} title="Anim" disabled={isInAnimatorsGroup || loading} />
      </Box>
      <Box>
        <ActionButton onClick={onCommon} title="Common" disabled={isCommonGroup || loading} />
      </Box>
    </>
  );
};
