import { useCallback, useState } from 'react';
import { ActionsLogger } from 'src/components/Moderation/utils/actions-logger';
import { LoggerMessages } from 'src/infrastructure/loggers/datadog';
import { moderationRequest } from 'src/network';
import { ActionType } from 'src/types/moderation';
import { Gender } from 'src/types/user';

import { useActionsContext, useDataContext } from './useContextHooks';
import { useModerationDetails } from './useModerationDetails';

export const useToggleGender = () => {
  const { operatorId, userId, realm } = useModerationDetails();
  const [isLoading, setIsLoading] = useState(false);
  const { gender } = useDataContext();
  const { updateGender } = useActionsContext();
  const isMan = gender === Gender.Male;

  const toggleGender = useCallback(() => {
    if (!gender) return;

    setIsLoading(true);

    const newGender = isMan ? Gender.Female : Gender.Male;
    const actions = [{ actionType: ActionType.SetGender, actionValue: newGender }];

    moderationRequest
      .sendAdminModerationActions(operatorId, userId, actions)
      .then((response) => {
        ActionsLogger.log(LoggerMessages.SendAdminUserModerationActions, {
          actions,
          product: realm || '',
          operatorId,
          userId,
          response,
        });

        updateGender(newGender);
      })
      .finally(() => setIsLoading(false));
  }, [gender, isMan, operatorId, realm, updateGender, userId]);

  return { gender, toggleGender, isLoading };
};
