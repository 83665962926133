import { object, string, mixed, SchemaOf } from 'yup';

import { CreateAgencyPayload } from 'src/services/agency/hooks';
import { AgencyCommission } from 'src/types/agency';
import { OperatorLanguage } from 'src/types/operator';
import { agencyCommissions, defaultCommission } from 'src/services/agency/config';

import { agencyLanguages } from './config';

export type CreateAgencyForm = CreateAgencyPayload;

export const defaultValues: CreateAgencyForm = {
  name: '',
  commission: defaultCommission,
  language: agencyLanguages[0],
};

export const validationSchema: SchemaOf<CreateAgencyForm> = object().shape({
  name: string().default(defaultValues.name).trim().required('Agency name is required'),
  commission: mixed<AgencyCommission>()
    .default(defaultValues.commission)
    .oneOf(agencyCommissions)
    .required('Commission is required'),
  language: mixed<OperatorLanguage>()
    .default(defaultValues.language)
    .oneOf(agencyLanguages)
    .required('Language is required'),
});
