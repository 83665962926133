import { Http } from 'src/network/http';

type UserRolesDto = string[];

const getAll = (ownerId: string) => {
  return Http.shared()
    .instance.get<UserRolesDto>(`/users/${ownerId}/roles`)
    .then((response) => response.data as UserRolesDto);
};

const setRole = (ownerId: string, role: string) => {
  return Http.shared().instance.put<UserRolesDto>(`/users/${ownerId}/roles/${role}`);
};

const deleteRole = (ownerId: string, role: string) => {
  return Http.shared().instance.delete<UserRolesDto>(`/users/${ownerId}/roles/${role}`);
};

export const userRolesRequest = {
  getAll,
  setRole,
  deleteRole,
};
