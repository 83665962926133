import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import { agencyRequest } from 'src/network/agency';
import { UserRoles } from 'src/types/user/roles';
import { useAuth } from 'src/services/auth';

export const useAgencyTechName = () => {
  const { me } = useAuth();

  const [agencyTechNameState, fetchAgencyTechName] = useAsyncFn(
    async () => {
      if (!me) {
        return undefined;
      }

      if (!me.roles.includes(UserRoles.Agency)) {
        return undefined;
      }

      const result = await agencyRequest.getAgencyName(me.id);
      return result;
    },
    [me?.id],
    { loading: true },
  );

  useEffect(() => {
    fetchAgencyTechName().then();
  }, [fetchAgencyTechName]);

  return agencyTechNameState;
};
