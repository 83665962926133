import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    backgroundColor: 'gray',
    display: 'flex',
    width: 'calc((100% - 30px)/6)',
    maxWidth: '300px',
    minWidth: '200px',
    aspectRatio: '0.87',
  },

  aiBubble: {
    position: 'absolute',
    top: '22px',
    left: '19px',
    zIndex: 3,
  },

  bottomBubbles: {
    position: 'absolute',
    bottom: '16px',
    left: '16px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    zIndex: 3,
  },

  whiteText: {
    color: 'white',
  },

  whiteTextUnderlined: {
    color: 'white',
    textDecoration: 'underline',
  },

  button: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    zIndex: 3,
  },

  verificationImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    zIndex: 2,
  },

  loader: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    zIndex: 1,
  },
}));
