import { makeStyles } from '@material-ui/core';

const bubbleStyles = {
  borderRadius: 50,
  padding: '6px 16px',
  backgroundColor: 'rgba(255, 255, 255, 0.60)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'flex-start',
};

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
  },

  largerPadding: {
    paddingTop: '20px',
  },

  contentContainer: {
    position: 'relative',
    display: 'grid',
    alignSelf: 'flex-start',
    justifySelf: 'flex: start',
  },

  imageContainer: {
    display: 'flex',
    borderRadius: '30px',
    overflow: 'hidden',
    position: 'relative',
  },

  gradient: {
    position: 'absolute',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 79.22%, rgba(0, 0, 0, 0.90) 100%)',
    zIndex: 1,
    width: '100%',
    height: '100%',
  },

  userPhoto: {
    width: '335px',
    height: '468px',
    objectFit: 'cover',
  },

  userNoPhotoContainer: {
    width: '335px',
    height: '468px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  userNoPhoto: {
    width: '214px',
    height: '302px',
    objectFit: 'contain',
  },

  userInfo: {
    position: 'absolute',
    bottom: '15px',
    left: '25px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 2,
  },

  bottomContainer: {
    padding: '14px 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
  },

  name: {
    fontSize: '34px',
    lineHeight: 'normal',
    color: 'white',
  },

  paginationContainer: {
    position: 'absolute',
    left: 0,
    top: '12px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  paginationIcon: {
    width: '33px',
    height: '6px',
  },

  likeIcon: {
    width: '75px',
    height: '75px',
  },

  infoText: {
    fontSize: '15px',
    lineHeight: 'normal',
    color: 'white',
  },

  likesCount: {
    ...bubbleStyles,
    position: 'absolute',
    top: '12px',
    left: '11px',
    zIndex: 3,
  },

  userId: {
    ...bubbleStyles,
    position: 'absolute',
    top: '12px',
    right: '11px',
    zIndex: 3,
  },

  popularBackground: {
    position: 'absolute',
    top: '-18px',
    left: '-14px',
    width: '363px',
    height: '188px',
  },
});
