import { useAsyncFn } from 'react-use';

import { onceModerationRequest } from 'src/network/moderation/once';

export const useModerationContent = (userId: string) => {
  const [moderationContentState, fetchModerationContentDecisions] = useAsyncFn(async () => {
    try {
      const result = await onceModerationRequest.getModerationContentDecisions(userId, 'reject');

      return result;
    } catch (e) {
      return null;
    }
  }, [userId]);

  return {
    moderationContentState,
    contentDecision: moderationContentState.value?.decisions ?? [],
    fetchModerationContentDecisions,
  };
};
