import { object, string, SchemaOf } from 'yup';

import { Agency } from 'src/types/agency';

export type ChangeAgencyActiveStatusForm = {
  agency: Pick<Agency, 'name' | 'techName'>;
};

export const defaultValues: ChangeAgencyActiveStatusForm = {
  agency: {
    name: '',
    techName: '',
  },
};

export const validationSchema: SchemaOf<ChangeAgencyActiveStatusForm> = object().shape({
  agency: object().shape({
    techName: string().required('Agency name is required'),
    name: string(),
  }) as SchemaOf<ChangeAgencyActiveStatusForm['agency']>,
});
