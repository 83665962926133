import React from 'react';
import { Container } from '@material-ui/core';

import { useStyles } from '../styles';
import { useDeleteAction } from '../hooks/useDeleteAction';
import { DeleteActionButton } from './delete-action-button';
import { useScamAction } from '../hooks/useScamAction';
import { ActionSwitchButton } from './action-button';
import { CreditGemsActionButton } from './credit-gems-action-button';
import { useAnimatorsParadiseAction } from '../hooks/useAnimatorsParadiseAction';
import { useEmailSubscriptionAction } from '../hooks/useEmailSubscriptionAction';
import { useCreditGemsAction } from '../hooks/useCreditGems';
import { useCancelPremiumAction } from '../hooks/useCancelPremiumAction';
import { useDataContext } from '../hooks/useContextHooks';

export const ActionsPanel = () => {
  const classes = useStyles();
  const { userTags } = useDataContext();
  const deleteAction = useDeleteAction();
  const scamAction = useScamAction();
  const animatorsParadiseAction = useAnimatorsParadiseAction();
  const emailSubscriptionAction = useEmailSubscriptionAction();
  const creditGemsAction = useCreditGemsAction();
  const cancelPremiumAction = useCancelPremiumAction();

  if (!userTags?.length) {
    return null;
  }

  return (
    <Container className={classes.actionsContainer}>
      <DeleteActionButton
        hidden={!deleteAction.available}
        disabled={deleteAction.isLoading}
        deleted={deleteAction.deleted}
        deleteUser={deleteAction.deleteUser}
      />

      {!deleteAction.deleted && (
        <>
          <ActionSwitchButton
            hidden={!scamAction.available}
            className={classes.scamAction}
            disabled={scamAction.isLoading || scamAction.disabled}
            onLabel="In Scam"
            offLabel="Scam"
            isOn={scamAction.scammed}
            onToggle={scamAction.setScam}
            confirmationEnabled
            confirmationTitle="Send to Scam"
          />
          <ActionSwitchButton
            hidden={!animatorsParadiseAction.available}
            className={classes.animatorsParadiseAction}
            disabled={animatorsParadiseAction.isLoading || animatorsParadiseAction.disabled}
            onLabel="In Paradise"
            offLabel={
              <>
                Animators
                <br />
                Paradise
              </>
            }
            isOn={animatorsParadiseAction.isInAnimatorsGroup}
            onToggle={animatorsParadiseAction.setAnimatorsGroup}
            confirmationEnabled
            confirmationTitle="Send to Animators Paradise"
          />
          <ActionSwitchButton
            hidden={!emailSubscriptionAction.available}
            disabled={emailSubscriptionAction.isLoading}
            onLabel="No Emails"
            offLabel={
              <>
                Unsubscribe
                <br />
                from Emails
              </>
            }
            isOn={emailSubscriptionAction.emailUnsubscribed}
            onToggle={emailSubscriptionAction.toggleEmailSubscription}
          />

          <ActionSwitchButton
            hidden={!cancelPremiumAction.available}
            className={classes.cancelPremiumAction}
            disabled={cancelPremiumAction.isLoading}
            onLabel="No Premium"
            offLabel={
              <>
                Cancel
                <br />
                Premium
              </>
            }
            isOn={cancelPremiumAction.unsubscribed}
            onToggle={cancelPremiumAction.unsubscribe}
          />

          <CreditGemsActionButton
            hidden={!creditGemsAction.available}
            balance={creditGemsAction.balance}
            disabled={creditGemsAction.isLoading}
            onAction={creditGemsAction.gemsChangeAction}
          />
        </>
      )}
    </Container>
  );
};
