import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAgencies, useChangeAgencyCommission } from 'src/services/agency/hooks';
import { agencyCommissions } from 'src/services/agency/config';

import { Form } from '../components/styled';
import { Select } from '../components/select';
import { SubmitButton } from '../components/submit-button';
import { AutocompleteInput } from '../components/autocomplete-input';

import { validationSchema, defaultValues } from './schema';

export const ChangeAgencyCommissionForm = () => {
  const changeAgencyCommission = useChangeAgencyCommission();
  const { agencies, loading, fetchAgencies } = useAgencies();

  const { control, handleSubmit, errors, formState, reset, watch, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleFormSubmit = handleSubmit(async (values) => {
    const success = await changeAgencyCommission({
      techName: values.agency.techName,
      name: values.agency.name,
      commission: values.commission,
    });
    if (success) {
      reset();
      fetchAgencies();
    }
  });

  const { techName: formAgencyTechName } = watch('agency');

  const selectedAgency = useMemo(() => agencies.find((agency) => agency.techName === formAgencyTechName), [
    agencies,
    formAgencyTechName,
  ]);

  useEffect(() => {
    if (loading || !selectedAgency) {
      return;
    }

    if (agencyCommissions.includes(selectedAgency.commission)) {
      setValue('commission', selectedAgency.commission);
    }
  }, [loading, selectedAgency, setValue]);

  return (
    <Form onSubmit={handleFormSubmit}>
      <AutocompleteInput
        name="agency"
        label="Agency name"
        control={control}
        errors={errors}
        loading={loading}
        options={agencies}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, selected) => option.techName === selected.techName}
      />
      {selectedAgency && (
        <Select
          name="commission"
          label="Agency commission"
          control={control}
          errors={errors}
          items={agencyCommissions}
        />
      )}
      <SubmitButton title="Change Agency Commission" submitting={formState.isSubmitting} />
    </Form>
  );
};
