import React, { useCallback } from 'react';

import { LabelText, LabelTextSize } from 'src/components/common/flure';

import deleteIcon from './delete-icon.svg';
import { useStyles } from './styles';

type Props = {
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
};

export const SelectedFilters = React.memo(({ selected, setSelected }: Props) => {
  const classes = useStyles();

  const onDelete = useCallback(
    (filter: string) => {
      setSelected((filters) => filters.filter((it) => it !== filter));
    },
    [setSelected],
  );

  const renderFilterItem = useCallback(
    (filter: string) => {
      return (
        <div key={filter} className={classes.item}>
          <LabelText text={filter} size={LabelTextSize.Small} />
          <button
            className={classes.deleteButton}
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              onDelete(filter);
            }}
          >
            <img src={deleteIcon} alt="del" />
          </button>
        </div>
      );
    },
    [classes.deleteButton, classes.item, onDelete],
  );

  return <div className={classes.selectedFilters}>{selected.map(renderFilterItem)}</div>;
});
