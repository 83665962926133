import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';

import { Layout } from 'src/components/App/views/Layout';
import { useVerificationPhotosHistory } from 'src/services/verification/hooks';

import { ScreenHeader, VerificationPhotosList } from './components';
import { useStyles } from './styles';

const defaultSelect = 20;

export const VerificationHistoryFlureScreen = () => {
  const classes = useStyles();
  const [appliedFilters, setAppliedFilters] = useState<string[]>([]);
  const { loading: isLoading, verificationPhotosHistory, fetchVerificationPhotos } = useVerificationPhotosHistory();

  const renderLoader = useCallback(() => {
    return (
      <div className={classes.loader}>
        <CircularProgress color="secondary" />
      </div>
    );
  }, [classes.loader]);

  const loadMore = useCallback(() => {
    fetchVerificationPhotos({
      omit: verificationPhotosHistory?.length,
      select: defaultSelect,
      mediaSources: appliedFilters,
      reload: false,
    });
  }, [appliedFilters, fetchVerificationPhotos, verificationPhotosHistory?.length]);

  useEffect(() => {
    fetchVerificationPhotos({ omit: 0, select: defaultSelect, mediaSources: appliedFilters, reload: true });
  }, [appliedFilters, fetchVerificationPhotos]);

  if (verificationPhotosHistory?.length === 0) {
    return (
      <Layout containerSize="xl">
        <ScreenHeader appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} />
        {isLoading ? (
          renderLoader()
        ) : (
          <Typography className={classes.placeholderText} variant="h4">
            No data available
          </Typography>
        )}
      </Layout>
    );
  }

  return (
    <Layout containerSize="xl">
      <ScreenHeader appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} />
      <VerificationPhotosList data={verificationPhotosHistory} loadMore={loadMore} />
    </Layout>
  );
};
