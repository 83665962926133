import React from 'react';
import clsx from 'clsx';

import { useStyles } from './styles';

type Props = {
  className?: string;
  children: React.ReactElement;
  title?: string;
};

export const Bubble = React.memo(({ children, className, title }: Props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)} title={title}>
      {children}
    </div>
  );
});
